// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app_launcher_closed {
    transform: scaleY(0) !important;
    opacity: 0 !important;
    display: none !important;
}

.app_launcher_opened {
  transform: scaleY(1) !important; 
    opacity: 1 !important;
}

.app_launcher_button_opened{
  background-color: var(--sdds-white) !important;
    color: var(--sdds-black)  !important;
  
}
.app_launcher_button_closed{
  background-color: var(--sdds-blue-800) !important;
  color: var(--sdds-white) !important;
}
.sdds-message-single {
  display: inline;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;IACI,+BAA+B;IAC/B,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;EACE,+BAA+B;IAC7B,qBAAqB;AACzB;;AAEA;EACE,8CAA8C;IAC5C,oCAAoC;;AAExC;AACA;EACE,iDAAiD;EACjD,mCAAmC;AACrC;AACA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.app_launcher_closed {\n    transform: scaleY(0) !important;\n    opacity: 0 !important;\n    display: none !important;\n}\n\n.app_launcher_opened {\n  transform: scaleY(1) !important; \n    opacity: 1 !important;\n}\n\n.app_launcher_button_opened{\n  background-color: var(--sdds-white) !important;\n    color: var(--sdds-black)  !important;\n  \n}\n.app_launcher_button_closed{\n  background-color: var(--sdds-blue-800) !important;\n  color: var(--sdds-white) !important;\n}\n.sdds-message-single {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
