import axios from "axios";

const api = () => {
  const token = localStorage.getItem(
    "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
  );
  const config = {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.create(config);
};

const api_rule = () => {
  const token = localStorage.getItem(
    "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
  );
  const config = {
    baseURL: process.env.REACT_APP_RULE_URL,
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.create(config);
};

export const getAllRoles = () => api().get("/role");
export const updateRole = (data: any) => api().put("/role", data);
export const createRole = (data: any) => api().post("/role", data);
export const actDeactRole = (roleId: any) => api().put(`/role/${roleId}`);
export const deleteRole = (roleId: any) => api().delete(`/role/${roleId}`);

// projects
export const getAllProjects = () => api_rule().get(`/project`);
export const getProjectsByprojectCode = (projectCode: any) =>
  api_rule().get(`/project?projectCode=${projectCode}`);
export const getByIdProjects = (projectId: any) =>
  api().get(`/projects?projectId=${projectId}`);
export const updateProject = (data: any) => api().put("/projects", data);
export const createProject = (data: any) => api().post("/projects", data);
export const actDeactProject = (projectId: any) =>
  api().put(`/projects/${projectId}`);
export const deleteProject = (projectId: any) =>
  api().delete(`/projects/${projectId}`);
export const getProjectDetails = (username: any) =>
  api_rule().get(`/projectUser?username=${username}`);
export const getUserDetails = (username: any) =>
  api_rule().get(`/user?username=${username}`);

//Topic
export const getAllTopics = () => api().get("/topics");
export const getTopicsByProjectId = (projectId) =>
  api().get(`/topics?projectId=${projectId}`);
export const updateTopic = (data: any) => api().put("/topics", data);
export const createTopic = (data: any) => api().post("/topics", data);
export const actDeactTopic = (topicId: any) => api().put(`/topics/${topicId}`);
export const deleteTopic = (topicId: any) => api().delete(`/topics/${topicId}`);

//Client
export const getAllClients = () => api().get("/clients");
export const getClientsByTopicId = (topicId) =>
  api().get(`/clients?topicId=${topicId}`);
export const updateClient = (data: any) => api().put("/clients", data);
export const createClient = (data: any) => api().post("/clients", data);
export const actDeactClient = (clientId: any) =>
  api().put(`/clients/${clientId}`);
export const deleteClient = (clientId: any) =>
  api().delete(`/clients/${clientId}`);

//Control center user
export const getAllControls = () => api().get("/ccusers");
export const getByIdControls = (username: any) =>
  api().get(`/ccusers?username=${username}`);
export const updateControl = (data: any) => api().put("/ccusers", data);
export const createControl = (data: any) => api().post("/ccusers", data);
export const actDeactControl = (id: any, projectId: any) =>
  api().put(`/ccusers/${id}/${projectId}`);
export const deleteControl = (id: any, projectId: any) =>
  api().delete(`/ccusers/${id}/${projectId}`);

// schema registry
export const getAllSchemas = () => api().get("/schemaregistry");
export const createSchema = (data: any) => api().post("/schemaregistry", data);
export const updateSchema = (data: any) => api().put("/schemaregistry", data);
export const deleteSchema = (id: any) => api().delete(`/schemaregistry/${id}`);

// Rule Engine
export const getAllRules = () => api_rule().get(`/ruleEngine`);
export const getRulesByProjectCode = (note: any) =>
  api_rule().get(`/ruleEngine?note=${note}`);
export const deleteRuleById = (ruleId: any) =>
  api_rule().delete(`/ruleEngine/${ruleId}`);
export const createRuleApi = (data: any) =>
  api_rule().post("/ruleEngine", data);
export const updateRuleApi = (data: any) => api_rule().put("/ruleEngine", data);
export const getActionData = (actionName: any) =>
  api_rule().get(`/ruleEngine/action?actionName=${actionName}`);
export const createActionApi = (data: any) =>
  api_rule().post("/ruleEngine/action", data);
export const updateActionApi = (data: any) =>
  api_rule().put("/ruleEngine/action", data);
