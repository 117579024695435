import React, { useState } from "react";
import contactIcon from "../Assets/Contact.svg";
import documentIcon from "../Assets/Document.svg";
import folderIcon from "../Assets/Folder.svg";
import profileIcon from "../Assets/Profile.svg";
import influxdbIcon from "../Assets/influxdbIcon.svg";
import ruleEngineIcon from "../Assets/rule_engine.svg";
import homeIcon from "../Assets/Home.svg";
import schemaRegistryIcon from "../Assets/schemaRegistry.svg";
import { useTranslation } from "react-i18next";

const Sidebar = ({ onMobViewMenu, loadPage }) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const sidebarCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [sideMenuActive, setSideMenuActive] = useState(null);

  // function to make selected menu active and load respected menu
  const selectedSideMenu = (menu) => {
    setSideMenuActive(menu);
    loadPage(menu);
  };

  const sideMenuCloseBtn = () => {
    document.getElementById("side-menu").classList.remove("mobile-menu-open");
  };

  //function to open url in another tab
  const openUrl = () => {
    // window.open(process.env.REACT_APP_ROLE_URL, "_blank");
  };

  const openInfluxUrl = () => {
    window.open(process.env.REACT_APP_INFLUXDB_URL, "_blank");
  };

  const openHome = () => {
    window.location.replace(process.env.REACT_APP_IOT_APP_URL);
  };

  return (
    <>
      <div
        id="side-menu"
        className={`sdds-sidebar side-menu ${isCollapsed ? "collapsed" : ""} ${
          onMobViewMenu ? "mobile-menu-open" : ""
        }`}
      >
        <div>
          <div className="sdds-sidebar-mheader">
            <a
              className="sdds-sidebar-mheader__close"
              onClick={sideMenuCloseBtn}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z"
                  fill="#171719"
                />
              </svg>
            </a>
          </div>
          <ul className="sdds-sidebar-nav sdds-sidebar-nav--bottom icons-enabled">
            <li className="sdds-sidebar-nav__item " onClick={sidebarCollapse}>
              <a
                className="sdds-sidebar-toggle sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <svg
                  className="sdds-sidebar-nav__icon"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.046 5.685a1 1 0 0 0-1.414-1.415l-9.9 9.9a2.6 2.6 0 0 0 0 3.678l9.9 9.9a1 1 0 1 0 1.415-1.415L4.722 17.01h24.306a1 1 0 0 0 0-2H4.722l9.325-9.324Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="sdds-sidebar-nav__item-text"></span>
              </a>
            </li>
          </ul>
          <ul className="sdds-sidebar-nav sdds-sidebar-nav--main sidemenuCollapseMarBot">
            <li
              onClick={() => openHome()}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={homeIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("home")}
                </span>
              </a>
            </li>
            {/* <li
              onClick={() => selectedSideMenu("project")}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start ${
                sideMenuActive == "project" ? "sdds-item--active" : ""
              }`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={folderIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("project")}
                </span>
              </a>
            </li>
            <li
              onClick={() => selectedSideMenu("control")}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start ${
                sideMenuActive == "control" ? "sdds-item--active" : ""
              }`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={profileIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("control-center-user")}
                </span>
              </a>
            </li> */}
            <li
              onClick={() => selectedSideMenu("topic")}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start ${
                sideMenuActive == "topic" ? "sdds-item--active" : ""
              }`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={documentIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("topic")}
                </span>
              </a>
            </li>
            <li
              onClick={() => selectedSideMenu("client")}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start ${
                sideMenuActive == "client" ? "sdds-item--active" : ""
              }`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={contactIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("client")}
                </span>
              </a>
            </li>
            <li
              onClick={() => selectedSideMenu("rule")}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={ruleEngineIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("rule-engine")}
                </span>
              </a>
            </li>
            <li
              onClick={() => openInfluxUrl()}
              className={`sdds-sidebar-nav__item sdds-u-textalign-start`}
            >
              <a
                className="sdds-sidebar-nav__item-link sidemenuListPad"
                href="#"
              >
                <img className="icon-btn" src={influxdbIcon} />

                <span className="sdds-sidebar-nav__item-text sdds-u-ml1">
                  {t("influx-db")}
                </span>
              </a>
            </li>
          </ul>
          <ul className="sdds-sidebar-nav sdds-sidebar-nav--bottom"></ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
