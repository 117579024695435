import React, { useState, useEffect, useContext } from "react";
import {
  getAllControls,
  updateControl,
  createControl,
  deleteControl,
} from "../../Services/Service.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import { passwordPattern } from "../../Constants.tsx";
import { useTranslation } from "react-i18next";

const ControlCenterModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllControlsFunc,
  projectArr,
  roleArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [passwordError, setPasswordError] = useState("");
  const [validationError, setValidationError] = useState("");
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);

  const [isChecked, setIsChecked] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const [passwordValidationError, setPasswordValidationError] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        ["roleId"]: { value: "", isChanged: true },
        ["projectId"]: { value: "", isChanged: true },
      }));
    }
  };

  const closeModalFunc = () => {
    setFormData({
      controlCenterId: { value: "", isChanged: false },
      newOrEdit: { value: "" },
      projectId: { value: "", isChanged: false },
      roleId: { value: "", isChanged: false },
      superAdmin: { value: true, isChanged: false },
      username: { value: "", isChanged: false },
    });
    closeModal();
  };

  useEffect(() => {
    setValidationError("");
    setFormData(formDataObj);
    setIsChecked(formDataObj.superAdmin.value);
  }, [formDataObj]);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "username") {
      emailValidation(value);
    }
    if (name == "password") {
      passwordValidation(value);
    }
  };

  const passwordValidation = (value) => {
    if (value != "" && !passwordPattern.test(value)) {
      setPasswordValidationError(
        t('paswd-validation-error')
      );
      return false;
    } else {
      setPasswordValidationError("");
      return true;
    }
  };

  const emailValidation = (value) => {
    if (value != "" && !emailPattern.test(value)) {
      setValidationError(t('control-username-email-error'));
      return false;
    } else {
      setValidationError("");
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidationError("");
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    let whenSuperAdminSelected = isChecked
      ? true
      : formData.projectId.value && formData.roleId.value;
    if (
      formData.username.value &&
      emailValidation(formData.username.value) &&
      whenSuperAdminSelected
    ) {
      const controlData = {
        username: formData.username.value,
        ccProjRole: [
          {
            projectId: isChecked ? "" : formData.projectId.value,
            roleId: isChecked ? "" : formData.roleId.value,
          },
        ],
        superAdmin: isChecked,
      };
      const existingControlData = {
        ...controlData,
        updatedBy: context.session.username,
        id: formData.controlCenterId.value,
      };
      //API call for update an existing role
      updateControl(existingControlData)
        .then((data: any) => {
          setFormData({
            controlCenterId: { value: "", isChanged: false },
            newOrEdit: { value: "" },
            projectId: { value: "", isChanged: false },
            roleId: { value: "", isChanged: false },
            superAdmin: { value: true, isChanged: false },
            username: { value: "", isChanged: false },
          });
          closeModal();
          getAllControlsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <sdds-modal
        id="modal-prog-control"
        size="xs"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">{t('edit-control-center')}</h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    validationError ||
                    (formData.username.isChanged && !formData.username.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  placeholder={t('enter-username')}
                  className="sdds-on-white-bg txt-bold"
                  name="username"
                  value={formData.username.value}
                  disabled={formData.newOrEdit?.value == "edit"}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('user-name')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.username.isChanged && !formData.username.value
                      ? t('username-error')
                      : ""}
                    {validationError}
                  </span>
                </sdds-textfield>
              </div>
              {context.session?.userRole?.toLowerCase() !=
              "mqtt_mgmt_projectadmin" ? (
                <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                  <div className="sdds-checkbox-item">
                    <input
                      className="sdds-form-input"
                      type="checkbox"
                      name="cb-example"
                      id="cb-option-1"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className="sdds-form-label" for="cb-option-1">
                      {t('is-super-user')}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">{t('role')}</span>
                  <select
                    name="roleId"
                    disabled={isChecked}
                    onChange={handleInputChange}
                    value={formData.roleId.value}
                    className={`${
                      !isChecked &&
                      formData.roleId.isChanged &&
                      !formData.roleId.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t('select-option')}
                    </option>
                    {context.session.userRole != "" &&
                    context.session.userRole != null &&
                    context.session.userRole == "mqtt_mgmt_projectadmin"
                      ? roleArr
                          .filter(
                            (item) =>
                              item.roleCode?.toLowerCase().trim("") == "pur" ||
                              item.roleCode?.toLowerCase().trim("") == "par"
                          )
                          .map((value) => (
                            <option value={value.roleId}>
                              {value.roleName}
                            </option>
                          ))
                      : roleArr.map((value) => (
                          <option value={value.roleId}>{value.roleName}</option>
                        ))}
                  </select>
                  {!isChecked &&
                  formData.roleId.isChanged &&
                  !formData.roleId.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t('select-role-error')}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">
                    {t('project-name')}
                  </span>
                  <select
                    name="projectId"
                    disabled={
                      (formData.newOrEdit?.value == "edit" && isChecked) ||
                      !formData.superAdmin.value
                    }
                    onChange={handleInputChange}
                    value={formData.projectId.value}
                    className={`${
                      !isChecked &&
                      formData.projectId.isChanged &&
                      !formData.projectId.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                    {t('select-option')}
                    </option>
                    {projectArr.map((value) => (
                      <option value={value.projectId}>
                        {value.projectName}
                      </option>
                    ))}
                  </select>
                  {!isChecked &&
                  formData.projectId.isChanged &&
                  !formData.projectId.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t('select-project-error')}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default ControlCenterModal;
