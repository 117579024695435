import React, { useState, useEffect, useContext } from "react";
import { LoggedInContext } from "../../Context/Context.tsx";
import plusIcon from "../../Assets/Plus.svg";
import trashIcon from "../../Assets/Minus.svg";
import {
  deleteSchema,
  createSchema,
  updateSchema,
} from "../../Services/Service.tsx";
import { specialCharsAndNumbersStartRestrictRegex } from "../../Constants.tsx";
import { useTranslation } from "react-i18next";

const CreateSchemaModal = ({
  formDataObj,
  closeModal,
  projectArr,
  getAllSchemasFunc,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [duplicateFieldKeys, setDuplicateFieldKeys] = useState([]);
  const [duplicateTagKeys, setDuplicateTagKeys] = useState([]);
  const context = useContext(LoggedInContext);
  const [measureValidationError, setMeasureValidationError] = useState("");
  const [fieldKeyValidationError, setFieldKeyValidationError] = useState("");
  const [tagKeyValidationError, setTagKeyValidationError] = useState("");

  // Function to set the input for bucket and measurement on typing
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "measurementName") {
      if (!specialCharsAndNumbersStartRestrictRegex.test(value)) {
        setMeasureValidationError(
          t('measure-name-error-validation')
        );
      } else {
        setMeasureValidationError("");
      }
    }
  };

  const checkFieldKeyValidation = () => {
    if (formData.fieldKeyValList.value.every((item) => item.isValid == true)) {
      setFieldKeyValidationError("");
    } else {
      setFieldKeyValidationError(
        t('field-key-error-validation')
      );
    }
  };

  const checkTagKeyValidation = () => {
    if (formData.tagKeyValList.value.every((item) => item.isValid == true)) {
      setTagKeyValidationError("");
    } else {
      setTagKeyValidationError(
        t('tag-key-error-validation')
      );
    }
  };

  // Function to set the value for fields inputs on typing
  const handleInputChangeField = (event, index) => {
    const { name, value } = event.target;
    let copyField = { ...formData };
    if (name == "fieldVal") {
      copyField.fieldKeyValList.value[index][name] = parseInt(value);
    } else {
      copyField.fieldKeyValList.value[index][name] = value;
      if (
        value != "" &&
        !specialCharsAndNumbersStartRestrictRegex.test(value)
      ) {
        copyField.fieldKeyValList.value[index]["isValid"] = false;
      } else {
        copyField.fieldKeyValList.value[index]["isValid"] = true;
      }
      checkFieldKeyValidation();
    }
    copyField.fieldKeyValList.value[index]["isChanged"] = true;
    setFormData(copyField);
  };

  // Function to set the value for Tags inputs on typing
  const handleInputChangeTag = (event, index) => {
    const { name, value } = event.target;
    let copyTag = { ...formData };
    copyTag.tagKeyValList.value[index][name] = value;
    copyTag.tagKeyValList.value[index]["isChanged"] = true;
    setFormData(copyTag);
    if (name == "tagKey") {
      if (
        value != "" &&
        !specialCharsAndNumbersStartRestrictRegex.test(value)
      ) {
        copyTag.tagKeyValList.value[index]["isValid"] = false;
      } else {
        copyTag.tagKeyValList.value[index]["isValid"] = true;
      }
      checkTagKeyValidation();
    }
  };

  const closeModalFunc = () => {
    //setFormData(formDataInit);
    closeModal();
  };

  //Function to add new new field inputs on add button click
  const addNewField = (event) => {
    event?.preventDefault();
    let copyField = { ...formData };
    let newRow = {
      fieldKey: "",
      fieldVal: 0,
      isChanged: false,
      isValid: true,
    };
    copyField.fieldKeyValList.value.push(newRow);
    setFormData(copyField);
    checkDuplicateFieldKeys();
  };

  //Function to delete particular field
  const deleteField = (event, index) => {
    event.preventDefault();
    let copyField = { ...formData };
    copyField.fieldKeyValList.value.splice(index, 1);
    setFormData(copyField);
    checkDuplicateFieldKeys();
  };

  //Function to add new new tag inputs on add button click
  const addNewTag = (event) => {
    event?.preventDefault();
    checkDuplicateTagKeys();
    let copyTag = { ...formData };
    let newRow = {
      tagKey: "",
      tagVal: "",
      isChanged: false,
      isValid: true,
    };
    copyTag.tagKeyValList.value.push(newRow);
    setFormData(copyTag);
  };

  //Function to delete particular Tag
  const deleteTag = (event, index) => {
    event.preventDefault();
    checkDuplicateTagKeys();
    let copyTag = { ...formData };
    copyTag.tagKeyValList.value.splice(index, 1);
    setFormData(copyTag);
  };

  // Function to add ischangd to true on typing field
  const fieldTagValConvert = (arr) => {
    let newArr = [];
    newArr = arr.map((item) => ({
      ...item,
      isChanged: true,
    }));
    return newArr;
  };

  //Function to check duplicate
  const checkDuplicateFieldKeys = () => {
    let duplicateFields = {};
    for (const [index, item] of formData.fieldKeyValList.value.entries()) {
      if (!duplicateFields[item.fieldKey]) {
        duplicateFields[item.fieldKey] = [index];
      } else {
        if (item.fieldKey != "") {
          duplicateFields[item.fieldKey].push(index);
        }
      }
    }
    let resultFieldKeys = Object.values(duplicateFields)
      .filter((ids) => ids.length > 1)
      .flat();
    setDuplicateFieldKeys(resultFieldKeys);
    if (resultFieldKeys.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  // Function to check duplicates in Tag
  const checkDuplicateTagKeys = () => {
    let duplicateTags = {};
    for (const [index, item] of formData.tagKeyValList.value.entries()) {
      if (!duplicateTags[item.tagKey]) {
        duplicateTags[item.tagKey] = [index];
      } else {
        if (item.tagKey != "") {
          duplicateTags[item.tagKey].push(index);
        }
      }
    }
    let resultTagKeys = Object.values(duplicateTags)
      .filter((ids) => ids.length > 1)
      .flat();
    setDuplicateTagKeys(resultTagKeys);
    if (resultTagKeys.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  // Function to submit details to API
  const handleSubmit = () => {
    setDuplicateFieldKeys([]);
    setDuplicateTagKeys([]);
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: Array.isArray(formData[key]["value"])
            ? fieldTagValConvert(formData[key].value)
            : formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    if (
      formData.bucketName.value &&
      formData.measurementName &&
      specialCharsAndNumbersStartRestrictRegex.test(
        formData.measurementName.value
      ) &&
      formData.fieldKeyValList.value.every((item) => item.fieldKey != "") &&
      formData.tagKeyValList.value.every((item) => item.tagKey != "") &&
      checkDuplicateFieldKeys() &&
      checkDuplicateTagKeys()
    ) {
      let schemaDetails = {
        bucketName: formData.bucketName.value,
        measurementName: formData.measurementName.value,
        fieldKeyValList: formData.fieldKeyValList.value,
        tagKeyValList: formData.tagKeyValList.value,
      };
      if (formData.newOrEdit?.value == "edit") {
        const existingSchemaData = {
          ...schemaDetails,
          updatedBy: context.session.username,
          schemaId: formData.schemaId.value,
        };

        updateSchema(existingSchemaData)
          .then((data) => {
            closeModal();
            getAllSchemasFunc();
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        const newSchemaData = {
          ...schemaDetails,
          createdBy: context.session.username,
        };
        createSchema(newSchemaData)
          .then((data) => {
            closeModal();
            getAllSchemasFunc();
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      }
    } else {
      console.log("elsse");
    }
  };

  useEffect(() => {
    setFormData(formDataObj);
    return () => {
      setDuplicateFieldKeys([]);
      setDuplicateTagKeys([]);
      setMeasureValidationError("");
      setTagKeyValidationError("");
      setFieldKeyValidationError("");
    };
  }, [formDataObj]);

  return (
    <>
      <sdds-modal
        id="modal-prog-schema"
        size="md"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {formData.newOrEdit?.value == "edit"
            ? t('edit-schema')
            : t('create-schema')}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-66 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">
                    {t('bucket-name-proj')}
                  </span>
                  <select
                    name="bucketName"
                    id="bucketName"
                    onChange={handleInputChange}
                    disabled={formData.newOrEdit?.value == "edit"}
                    value={formData.bucketName.value}
                    className={`sdds-u-mt2 ${
                      formData.bucketName.isChanged &&
                      !formData.bucketName.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t('select-option')}
                    </option>
                    {projectArr.map((value) => (
                      <option key={value.projectId} value={value.projectCode}>
                        {value.projectCode}
                      </option>
                    ))}
                  </select>
                  {formData.bucketName.isChanged &&
                  !formData.bucketName.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t('bucket-name-error')}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* measurement field */}
              <div className="sdds-u-mt3 sdds-col-max-6 sdds-col-xxlg-6 sdds-col-xlg-6 sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-6 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    formData.measurementName.errorMsg ||
                    measureValidationError ||
                    (formData.measurementName.isChanged &&
                      !formData.measurementName.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  disabled={formData.newOrEdit?.value == "edit"}
                  placeholder={t('enter-measurement-name')}
                  className="sdds-on-white-bg txt-bold"
                  name="measurementName"
                  value={formData.measurementName.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('measurement-name')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.measurementName.isChanged &&
                    !formData.measurementName.value
                      ? t('measure-name-error')
                      : measureValidationError}
                    {formData.measurementName.errorMsg
                      ? formData.measurementName.errorMsg
                      : ""}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <span className="sdds-dropdown-label-outside sdds-u-textalign-start">
                  {t('fields')}
                </span>
                <div className="overflow-auto">
                  <table className="sdds-table sdds-u-w-100 sdds-table--compact">
                    <thead className="sdds-table__header">
                      <tr className="sdds-table__row">
                        <th
                          className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                        >
                          {t('key')}
                        </th>
                        <th
                          className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                        >
                          {t('value')}
                        </th>
                        <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                          {formData.fieldKeyValList.value.length < 1 ? (
                            <button
                              id="addNewField"
                              className="sdds-btn sdds-btn-primary sdds-btn-sm  schemaAddBtn"
                              onClick={(event) => addNewField(event)}
                            >
                              {t('add')}
                            </button>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="sdds-table__body">
                      {formData.fieldKeyValList.value &&
                        formData.fieldKeyValList.value.map((item, index) => (
                          <tr className="sdds-table__row">
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                              <input
                                className={`sdds-on-white-bg txt-bold pad-input-custom  ${
                                  (!item.fieldKey &&
                                    formData.fieldKeyValList.value[index]
                                      .isChanged) ||
                                  duplicateFieldKeys.includes(index) ||
                                  !formData.fieldKeyValList.value[index].isValid
                                    ? "errorInput"
                                    : ""
                                }`}
                                label={t('field-key')}
                                label-position="outside"
                                placeholder={t('enter-field-key')}
                                name="fieldKey"
                                value={item.fieldKey}
                                onInput={(event) =>
                                  handleInputChangeField(event, index)
                                }
                              />
                            </td>
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                              <input
                                className="sdds-on-white-bg txt-bold pad-input-custom sdds-u-w-100"
                                label={t('field-value')}
                                label-position="outside"
                                type="number"
                                placeholder={t('enter-field-value')}
                                name="fieldVal"
                                value={item.fieldVal}
                                onInput={(event) =>
                                  handleInputChangeField(event, index)
                                }
                              />
                            </td>
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1 sdds-u-pt0 sdds-u-pb0">
                              <div className="div-btn-horz">
                                <button
                                  className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                  onClick={(event) => addNewField(event)}
                                >
                                  <img
                                    className="icon-btn-schema"
                                    src={plusIcon}
                                  />
                                </button>
                                {formData.fieldKeyValList.value.length > 1 ? (
                                  <button
                                    fill="white"
                                    className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                    onClick={(event) =>
                                      deleteField(event, index)
                                    }
                                  >
                                    <img
                                      fill="white"
                                      className="icon-btn-schema"
                                      src={trashIcon}
                                    />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {fieldKeyValidationError ? (
                  <p className="fieldTableError">{fieldKeyValidationError}</p>
                ) : (
                  ""
                )}
                {!fieldKeyValidationError && duplicateFieldKeys.length > 0 ? (
                  <p className="fieldTableError">{t('field-key-error')}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <span className="sdds-dropdown-label-outside sdds-u-textalign-start">
                  {t('tags')}
                </span>
                <div className="overflow-auto">
                  <table className="sdds-table sdds-u-w-100 sdds-table--compact">
                    <thead className="sdds-table__header">
                      <tr className="sdds-table__row">
                        <th
                          className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                        >
                          {t('key')}
                        </th>
                        <th
                          className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                        >
                          {t('value')}
                        </th>
                        <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                          {formData.tagKeyValList.value.length < 1 ? (
                            <button
                              id="addNewTag"
                              className="sdds-btn sdds-btn-primary sdds-btn-sm schemaAddBtn "
                              onClick={(event) => addNewTag(event)}
                            >
                              {t('add')}
                            </button>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="sdds-table__body">
                      {formData.tagKeyValList.value &&
                        formData.tagKeyValList.value.map((item, index) => (
                          <tr className="sdds-table__row">
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                              <input
                                className={`sdds-on-white-bg txt-bold pad-input-custom  ${
                                  (!item.tagKey &&
                                    formData.tagKeyValList.value[index]
                                      .isChanged) ||
                                  duplicateTagKeys.includes(index) ||
                                  !formData.tagKeyValList.value[index].isValid
                                    ? "errorInput"
                                    : ""
                                }`}
                                label={t('tag-key')}
                                label-position="outside"
                                placeholder={t('enter-tag-key')}
                                name="tagKey"
                                value={item.tagKey}
                                onInput={(event) =>
                                  handleInputChangeTag(event, index)
                                }
                              />
                            </td>
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                              <input
                                className="sdds-on-white-bg txt-bold pad-input-custom sdds-u-w-100"
                                label={t('tag-value')}
                                label-position="outside"
                                placeholder={t('enter-tag-value')}
                                name="tagVal"
                                value={item.tagVal}
                                onInput={(event) =>
                                  handleInputChangeTag(event, index)
                                }
                              />
                            </td>
                            <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1 sdds-u-pt0 sdds-u-pb0">
                              <div className="div-btn-horz">
                                <button
                                  className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                  onClick={(event) => addNewTag(event)}
                                >
                                  <img
                                    className="icon-btn-schema"
                                    src={plusIcon}
                                  />
                                </button>
                                {formData.tagKeyValList.value.length > 1 ? (
                                  <button
                                    fill="white"
                                    className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                    onClick={(event) => deleteTag(event, index)}
                                  >
                                    <img
                                      fill="white"
                                      className="icon-btn-schema"
                                      src={trashIcon}
                                    />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {tagKeyValidationError ? (
                  <p className="fieldTableError">{tagKeyValidationError}</p>
                ) : (
                  ""
                )}
                {!tagKeyValidationError && duplicateTagKeys.length > 0 ? (
                  <p className="fieldTableError">{t('tag-key-error')}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default CreateSchemaModal;
