import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getProjectDetails, getUserDetails } from "../Services/Service.tsx";
import mqttIcon from "../Assets/filters.svg";
import assetManagerIcon from "../Assets/Folder.svg";
import dashboardsIcon from "../Assets/report.svg";
import influxdbIcon from "../Assets/redirect.svg";
import grafanaIcon from "../Assets/redirect.svg";
import emqxIcon from "../Assets/redirect.svg";


const Header = ({
  isDesktop,
  mobileMenuClick,
  logoutFunc,
  session,
  username,
  selectedProjectFunc
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [projectArr, setProjectArr] = useState([]);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const [openAppLauncher, setOpenAppLauncher] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const refElement = useRef(null);
  const refElement1 = useRef(null);
  const [openInlineDropdown, setOpenInlineDropdown] = useState(false);
  const [openBentoDropdown, setOpenBentoDropdown] = useState(false);
  const [selectedProjectCode, setSelectedProjectCode] = useState("");




  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the element
      if (refElement.current && !refElement.current.contains(event.target)) {
        setShowAvatarMenu(false);
      }
      if (refElement1.current && !refElement1.current.contains(event.target)) {
        setOpenAppLauncher(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleAppLauncher = (e) => {
    setOpenBentoDropdown(false);
    setOpenAppLauncher(!openAppLauncher);
  };

  const toggleInlineDropdown = () => {
    setOpenInlineDropdown(!openInlineDropdown);
  };

  const toggleBentoDropdown = () => {
    setOpenBentoDropdown(!openBentoDropdown);
    setOpenBentoDropdown(true);
    if(openBentoDropdown){
      setOpenBentoDropdown(false);
    }
  };
  

  const languageChange = (langCode: any) => {
    i18n.changeLanguage(langCode);
  };

  const logout = () => {
    logoutFunc();
  };

  const sidebarOpenforMob = () => {
    mobileMenuClick(openMobileMenu);
  };

  useEffect(()=>{
    if(username){      
      getProjectDetailFunc();   
    }  
  },[username, projectArr.length])

  const getProjectDetailFunc = () => {
    getProjectDetails(username)
      .then((data) => {
        if(data.data.length > 0){
          selectedProjectFunc(data.data[0]) 
          setProjectArr(data.data);
          setSelectedProjectId(data.data[0].projectUserId);
          setSelectedProjectCode(data.data[0].projectCode)
        } else{
          getUserDetails(username)
            .then((data) => {
              if(data.data.length > 0 && data.data[0].superAdmin){
                setProjectArr([{"projectCode": "All"}]);
                selectedProjectFunc("All");
                setSelectedProjectCode("All")
              } else {
                setProjectArr([{"projectCode": ""}]);
                selectedProjectFunc("");
                setSelectedProjectCode("")
              }
            })
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let selectedProj = projectArr.filter((proj: any) =>
      proj.projectCode == value
    );
    selectedProjectFunc(selectedProj[0]) 
  };
  
  const handleProjectSelect = (projectCode) => {
    setSelectedProjectCode(projectCode);
    const selectedProj = projectArr.find(proj => proj.projectCode === projectCode);
    selectedProjectFunc(selectedProj);
    setOpenInlineDropdown(false); // Close the dropdown after selection
  };

  return (
    <nav
      className={`sdds-nav ${
        showAvatarMenu ? "sdds-nav__avatar--opened" : ""
      } ${openMobileMenu ? "sdds-nav__mob-menu--opened" : ""}
     `}
    >
      <div className="sdds-nav__left">
        <div
          className={`${showOverlay ? "sdds-nav__overlay" : ""}`}
          onClick={() => setShowOverlay(false)}
        ></div>
        <button
          className="sdds-nav__mob-menu-btn"
          onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
            setShowOverlay(true);
            sidebarOpenforMob();
          }}
        >
          <div id="sdds-nav__mob-menu-icon">
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-1"
            ></span>
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-2"
            ></span>
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-3"
            ></span>
          </div>
        </button>
        <div className="sdds-nav__app-name">{t("mqtt-management")}</div>
      </div>
      
      <div className="sdds-nav__center">
        
      </div>
      

        {/* New Dropdown Implementation */}
        <ul className="sdds-nav__inline-menu">
          <li className={`sdds-nav__item sdds-nav__dropdown ${openInlineDropdown ? 'sdds-nav__dropdown--opened' : ''}`}>
            <button className="sdds-nav__item-core" onClick={toggleInlineDropdown}>
              <p className="sdds-nav__item-core-text">{selectedProjectCode}</p>
              <span className="sdds-nav__dropdown-icon">
                <svg className="sdds-nav__dropdown-icon-svg" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" fill="currentColor" d="M1.13668 0.891564C1.33194 0.696302 1.64853 0.696302 1.84379 0.891564L6.78786 5.83563C6.90501 5.95278 7.09496 5.95278 7.21212 5.83563L12.1562 0.891564C12.3515 0.696302 12.668 0.696303 12.8633 0.891565C13.0586 1.08683 13.0586 1.40341 12.8633 1.59867L7.91923 6.54273C7.41155 7.05041 6.58843 7.05041 6.08075 6.54273L1.13668 1.59867C0.941419 1.40341 0.941419 1.08683 1.13668 0.891564Z" />
                </svg>                                       
              </span>
            </button>
            {openInlineDropdown && (
              <ul className="sdds-nav__dropdown-menu">
                {projectArr.map((project) => (
                  <li
                    key={project.projectCode}
                    className={`sdds-nav__dropdown-item ${selectedProjectCode === project.projectCode ? 'sdds-nav__dropdown-item--active' : ''}`}
                    onClick={() => handleProjectSelect(project.projectCode)}
                    onChange={handleInputChange}
                  >
                    <a href="#" className="sdds-nav__dropdown-item-core">
                      {project.projectCode}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
                
      <div
        className="sdds-nav__item sdds-nav__app-launcher custom-app-laucher-div"
        ref={refElement1}
      >
        <button
          className={`sdds-nav__app-launcher-btn  ${openAppLauncher ? "app_launcher_button_opened":"app_launcher_button_closed"}`}
          onClick={(event) => toggleAppLauncher(event)}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 2C8.26801 2 2 8.26802 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM28 16C28 17.4025 27.7594 18.7489 27.3172 20H23.6767C23.8877 18.729 24 17.3855 24 16C24 14.6145 23.8877 13.271 23.6767 12H27.3172C27.7594 13.2511 28 14.5975 28 16ZM23.2481 22H26.3946C25.1703 24.1165 23.3181 25.8243 21.0928 26.8688C21.3574 26.4902 21.6035 26.0875 21.8302 25.6665C22.4092 24.5913 22.8885 23.3539 23.2481 22ZM22 16C22 17.4076 21.8746 18.7527 21.6466 20H17V12H21.6466C21.8746 13.2473 22 14.5924 22 16ZM15 12V20H10.3534C10.1254 18.7527 10 17.4076 10 16C10 14.5924 10.1254 13.2473 10.3534 12H15ZM17 10H21.1705C20.8698 8.99114 20.4973 8.07658 20.0693 7.28171C19.1572 5.58793 18.0773 4.56893 17 4.18062V10ZM15 4.18062V10H10.8295C11.1302 8.99114 11.5027 8.07658 11.9307 7.28171C12.8428 5.58793 13.9227 4.56893 15 4.18062ZM15 22H10.8295C11.1302 23.0089 11.5027 23.9234 11.9307 24.7183C12.8428 26.4121 13.9227 27.4311 15 27.8194V22ZM17 27.8194C18.0773 27.4311 19.1572 26.4121 20.0693 24.7183C20.4973 23.9234 20.8698 23.0089 21.1705 22H17V27.8194ZM8 16C8 17.3855 8.11225 18.729 8.3233 20H4.68282C4.24062 18.7489 4 17.4025 4 16C4 14.5975 4.24062 13.2511 4.68282 12H8.3233C8.11225 13.271 8 14.6145 8 16ZM8.75193 22H5.60539C6.8297 24.1165 8.68185 25.8243 10.9072 26.8688C10.6426 26.4902 10.3965 26.0875 10.1698 25.6665C9.59084 24.5913 9.1115 23.3539 8.75193 22ZM26.3946 10C25.1703 7.88354 23.3181 6.1757 21.0928 5.13117C21.3574 5.50978 21.6035 5.91254 21.8302 6.33351C22.4092 7.40869 22.8885 8.64609 23.2481 10H26.3946ZM8.75193 10C9.1115 8.64609 9.59084 7.40869 10.1698 6.33351C10.3965 5.91253 10.6426 5.50978 10.9072 5.13117C8.68185 6.1757 6.82969 7.88353 5.60538 10H8.75193Z"
              fill="currentColor"
            />
          </svg>
        </button>
        
        <ul  className={`sdds-nav__app-launcher-menu custom-app-laucher-ul  ${openAppLauncher ? "app_launcher_opened":"app_launcher_closed"}`} >
          <li className="sdds-nav__app-launcher-item custom-width-logout">
            <a
              className="sdds-nav__app-launcher-item-core"
              onClick={() => languageChange("en")}
            >
             English
            </a>
          </li>
          <li className="sdds-nav__app-launcher-item custom-width-logout">
            <a
              className="sdds-nav__app-launcher-item-core"
              onClick={() => languageChange("zh")}
            >
              中文（简体
            </a>
          </li>
          <li className="sdds-nav__app-launcher-item custom-width-logout">
            <a
              className="sdds-nav__app-launcher-item-core"
              onClick={() => languageChange("pt")}
            >
              Português
            </a>
          </li>
        </ul>
        
      </div>
      
      <div class='sdds-nav__right'>
    <div class='sdds-nav__item sdds-nav__app-launcher'>
        <button className={`sdds-nav__app-launcher-btn  ${openBentoDropdown ? "app_launcher_button_opened":"app_launcher_button_closed"}`} onClick={()=>toggleBentoDropdown()}>
            <svg class="sdds-nav__app-launcher-btn-svg" viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' clip-rule='evenodd' d='M1.33333 2.66667C2.06971 2.66667 2.66667 2.06971 2.66667 1.33333C2.66667 0.596954 2.06971 0 1.33333 0C0.596954 0 0 0.596954 0 1.33333C0 2.06971 0.596954 2.66667 1.33333 2.66667ZM9.33307 1.33333C9.33307 2.06971 8.73612 2.66667 7.99974 2.66667C7.26336 2.66667 6.66641 2.06971 6.66641 1.33333C6.66641 0.596954 7.26336 0 7.99974 0C8.73612 0 9.33307 0.596954 9.33307 1.33333ZM16.0003 1.33333C16.0003 2.06971 15.4033 2.66667 14.6669 2.66667C13.9305 2.66667 13.3336 2.06971 13.3336 1.33333C13.3336 0.596954 13.9305 0 14.6669 0C15.4033 0 16.0003 0.596954 16.0003 1.33333ZM16.0003 8C16.0003 8.73638 15.4033 9.33333 14.6669 9.33333C13.9305 9.33333 13.3336 8.73638 13.3336 8C13.3336 7.26362 13.9305 6.66667 14.6669 6.66667C15.4033 6.66667 16.0003 7.26362 16.0003 8ZM14.6669 16C15.4033 16 16.0003 15.403 16.0003 14.6667C16.0003 13.9303 15.4033 13.3333 14.6669 13.3333C13.9305 13.3333 13.3336 13.9303 13.3336 14.6667C13.3336 15.403 13.9305 16 14.6669 16ZM7.99974 9.33333C8.73612 9.33333 9.33307 8.73638 9.33307 8C9.33307 7.26362 8.73612 6.66667 7.99974 6.66667C7.26336 6.66667 6.66641 7.26362 6.66641 8C6.66641 8.73638 7.26336 9.33333 7.99974 9.33333ZM9.33307 14.6667C9.33307 15.403 8.73612 16 7.99974 16C7.26336 16 6.66641 15.403 6.66641 14.6667C6.66641 13.9303 7.26336 13.3333 7.99974 13.3333C8.73612 13.3333 9.33307 13.9303 9.33307 14.6667ZM2.66667 8C2.66667 8.73638 2.06971 9.33333 1.33333 9.33333C0.596954 9.33333 0 8.73638 0 8C0 7.26362 0.596954 6.66667 1.33333 6.66667C2.06971 6.66667 2.66667 7.26362 2.66667 8ZM1.33333 16C2.06971 16 2.66667 15.403 2.66667 14.6667C2.66667 13.9303 2.06971 13.3333 1.33333 13.3333C0.596954 13.3333 0 13.9303 0 14.6667C0 15.403 0.596954 16 1.33333 16Z' fill='currentColor' fill-opacity='1'/>
            </svg>
        </button>
        
        <ul className={`sdds-nav__app-launcher-menu custom-app-laucher-ul  ${openBentoDropdown ? "app_launcher_opened":"app_launcher_closed"}`} > 
          
        
            <li class='sdds-nav__app-launcher-item sdds-nav__app-launcher-item--category'>
                <p class='sdds-nav__app-launcher-category-title'>IOT DASH SERVICES</p>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_MQTT_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={mqttIcon} alt="MQTT Manager" className="icon-btn" />
                  {t('mqtt-manager')}
                </a>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_ASSET_MANAGEMENT_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={assetManagerIcon} alt="Asset Manager" className="icon-btn" />
                  {t('asset-modelling')}
                </a>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_SELF_SERVICE_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={dashboardsIcon} alt="Dashboards" className="icon-btn" />
                  {t('dashboards')}
                </a>
            </li>
            <li class='sdds-nav__app-launcher-item sdds-nav__app-launcher-item--category'>
                <p class='sdds-nav__app-launcher-category-title'>EXTERNAL SERVICES</p>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_INFLUXDB_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={influxdbIcon} alt="InfluxDb" className="icon-btn" />
                  {t('influxdb')}
                </a>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_GRAFANA_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={grafanaIcon} alt="Grafana" className="icon-btn" />
                  {t('grafana')}
                </a>
            </li>
            <li class='sdds-nav__app-launcher-item'>
                <a href={process.env.REACT_APP_EMQX_URL} class='sdds-nav__app-launcher-item-core'>
                  <img src={emqxIcon} alt="EMQX" className="icon-btn" />
                  {t('emqx')}
                </a>
            </li>
        </ul>
    </div>
</div>


      {session ? (
        <ul className="sdds-nav__toolbar-menu" ref={refElement}>
          <li className="sdds-nav__item sdds-nav__avatar">
            <button
              className="sdds-nav__avatar-btn"
              onClick={() => setShowAvatarMenu(!showAvatarMenu)}
            >
              <img
                className="sdds-nav__avatar-img"
                src="https://www.svgrepo.com/show/170303/avatar.svg"
                alt="profile photo"
              />
            </button>

            <ul className="sdds-nav__avatar-menu">
              <li className="sdds-nav__avatar-item  custom-width-logout">
                <span className="sdds-nav__avatar-item-core">{username}</span>
              </li>
              <li
                className="sdds-nav__avatar-item  custom-width-logout"
                onClick={() => logout()}
              >
                <span className="sdds-nav__avatar-item-core">
                  {t("logout")}
                </span>
              </li>
            </ul>
          </li>
        </ul>
      ) : (
        ""
      )}
      <div className="sdds-nav__right">
        <a className="sdds-nav__item sdds-nav__app-logo" href="#"></a>
      </div>
    </nav>
  );
};

export default Header;
