import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import ClientUserModal from "./Modals/ClientUserModal.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";
import searchIcon from "../Assets/search.svg";
import {
  getAllClients,
  updateClient,
  createClient,
  deleteClient,
  actDeactClient,
  getAllProjects,
  getAllTopics,
  getProjectsByprojectCode,
  getTopicsByProjectId,
  getClientsByTopicId,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import { PageSize } from "../Constants.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const ClientUser = () => {
  const { t } = useTranslation();
  let formDataInit = {
    clientUsername: { value: "", isChanged: false },
    projectId: { value: "", isChanged: false },
    topicId: { value: "", isChanged: false },
    authType: { value: "", isChanged: false },
    access: { value: "", isChanged: false },
    password: { value: "", isChanged: false },
    confirmPassword: { value: "", isChanged: false },
    clientId: { value: "", isChanged: false },
  };

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [clientArr, setClientArr] = useState([]);
  const [filterClientArr, setFilterClientArr] = useState([]);
  const [projectArr, setProjectArr] = useState([]);
  const [topicArr, setTopicArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(clientArr);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const context = useContext(LoggedInContext);
  const [projectIdBasedOnRole, setProjectIdBasedOnRole] = useState([]);
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [searchClientVal, setSearchClientVal] = useState("");
  const [clientNameVal, setClientNameVal] = useState("");
  const [actDeactStatus, setActDeactStatus] = useState("");

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  //Handling search input
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchClientVal(value);
    let filterArray = filterClientArr.filter((client: any) =>
      client.clientUsername.toLowerCase().includes(value.toLowerCase())
    );
    setClientArr(filterArray);
  };

  // function to activate/deactivate Control center user
  const handleCheckboxChange = (
    status,
    clientId: Number,
    clientName: string
  ) => {
    setActDeactOrDelete("actDeact");
    setSelectedClientId(clientId);
    setClientNameVal(clientName);
    setActDeactStatus(status);
    if (status) {
      setWarningMessage(
        `${t("deactive-client")} "${
          clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
            .clientUsername
        }" ?`
      );
    } else {
      setWarningMessage(
        `${t("active-client")} "${
          clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
            .clientUsername
        }" ?`
      );
    }
    document.getElementById("confirmationMessageModal").openModal();
  };

  const confirmedFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    setModeforResponseMessageModal("");
    if (actDeactOrDelete == "actDeact") {
      actDeactClient(selectedClientId)
        .then((data: any) => {
          if (actDeactStatus) {
            setResponseMessage(
              `${t("client")} ${clientNameVal} ${t("deactive-success")}`
            );
          } else {
            setResponseMessage(
              `${t("client")} ${clientNameVal} ${t("active-success")}`
            );
          }
          document.getElementById("responseMessageModal").openModal();
          if (context.session.projectCode != "All") {
            getTopicsFunc(projectIdBasedOnRole);
          } else {
            getAllClientsFunc();
          }
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteClient(selectedClientId)
        .then((data: any) => {
          setResponseMessage(
            `${t("client")} ${clientNameVal} ${t("delete-success")}`
          );
          document.getElementById("responseMessageModal").openModal();
          if (context.session.projectCode != "All") {
            getTopicsFunc(projectIdBasedOnRole);
          } else {
            getAllClientsFunc();
          }
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on status 500
  const showErrorModal = (message) => {
    document.getElementById("modal-prog-client").closeModal();
    setResponseMessage(message);
    document.getElementById("responseMessageModal").openModal();
    setModeforResponseMessageModal("error");
  };

  const getStatus = (status) => {
    return status == "active" ? true : false;
  };

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  //Function to create new Client
  const addNew = () => {
    setFormDataObj(formDataInit);
    setShowModal(!showModal);
    document.getElementById("modal-prog-client").openModal();
  };

  //Functoin to close Create or Edit modal
  const closeModal = () => {
    document.getElementById("modal-prog-client").closeModal();
  };

  const editClient = (clientId) => {
    let selectedClient =
      clientArr[clientArr.findIndex((x) => x.clientId == clientId)];
    let selectedClientDetails = {};
    for (let key in formDataInit) {
      selectedClientDetails[key] = {
        value: selectedClient[key],
        isChanged: false,
      };
    }

    setFormDataObj((prevData) => ({
      ...selectedClientDetails,
      ["newOrEdit"]: { value: "edit" },
      ["basicOrClient"]: { value: selectedClientDetails.authType.value },
    }));
    setShowModal(!showModal);
    document.getElementById("modal-prog-client").openModal();
  };

  //Function to send selected Client details for delete to Confirmation modal
  const deleteClientFunc = (clientId: any, clientName: string) => {
    setSelectedClient(
      clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
    );
    setSelectedClientId(clientId);
    setActDeactOrDelete("delete");
    setClientNameVal(clientName);
    setWarningMessage(
      `${t("delete-client")} "${
        clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
          .clientUsername
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  // Function to delete the client
  const confirmDelete = (clientId: any) => {
    setShowDeleteModal(!showDeleteModal);
    deleteClient(clientId)
      .then((data: any) => {
        if (context.session.projectCode != "All") {
          getTopicsFunc(projectIdBasedOnRole);
        } else {
          getAllClientsFunc();
        }
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  // API call to get all clients
  const getAllClientsFunc = async (topicIdArr = []) => {
    const projectCode = context.session.projectCode;
    if (projectCode == "All") {
      getAllClients()
        .then((data: any) => {
          setClientArr(data.data);
          setFilterClientArr(data.data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (projectCode != "" && projectCode != null) {
      let tempClientArr = await Promise.all(
        topicIdArr.map(async (topic) => {
          return getClientsByTopicId(topic)
            .then((data: any) => {
              return data.data;
            })
            .catch((err) => {
              if (err?.response?.status == 401) {
                context.setSession({
                  ["username"]: "",
                  ["loggedin"]: false,
                  ["projectCode"]: [],
                  ["userRole"]: "",
                });
                localStorage.clear();
                window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
              }
            });
        })
      );

      let flatClientArr = flattenArray(tempClientArr);
      setClientArr(flatClientArr);
      setFilterClientArr(flatClientArr);
    }
    setCurrentPage(1);
  };

  function flattenArray(arr) {
    return arr.reduce(
      (acc, val) =>
        Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
      []
    );
  }

  //API call to get all the projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects()
        .then((data: any) => {
          setProjectArr(data.data);
          getTopicsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getProjectsByprojectCode(context.session.projectCode)
        .then((data) => {
          setProjectIdBasedOnRole(data.data[0].projectId);
          setProjectArr(data.data);
          getTopicsFunc(data.data[0].projectId);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  const getTopicsFunc = (projectId = "") => {
    if (context.session.projectCode == "All") {
      getAllTopics()
        .then((data) => {
          setTopicArr(data.data);
          getAllClientsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getTopicsByProjectId(projectId)
        .then((data: any) => {
          let tempTopicIdArr = [];
          setTopicArr(data.data);
          data.data.map((response: any) => {
            tempTopicIdArr.push(response.topicId);
          });
          getAllClientsFunc(tempTopicIdArr);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  useEffect(() => {
    getAllProjectsFunc();
  }, [context.session.projectCode]);

  const getProjectName = (projectId: any) => {
    let val = projectArr.findIndex((x) => x.projectId == projectId);
    return val > -1 ? projectArr[val].projectName : "";
  };

  const getTopicName = (topicId: any) => {
    let val = topicArr.findIndex((x) => x.topicId == topicId);
    return val > -1 ? topicArr[val].topicName : "";
  };

  return (
    <>
      <div
        className={
          context.session.userRole != "mqtt_mgmt_projectuser"
            ? "search-align sdds-u-mb1"
            : "sdds-u-mb1"
        }
      >
        {context.session.userRole != "mqtt_mgmt_projectuser" ? (
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-block"
            onClick={addNew}
            disabled={projectArr.length == 0}
          >
            {t("create-client")}
          </button>
        ) : (
          ""
        )}
        {projectArr.length == 0 ? (
          <div class="sdds-message sdds-message__type-warning sdds-message__icon-active sdds-message__extended-active sdds-message-ongrey">
              <svg class="sdds-message-icon sdds-message-icon--warning" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25175 9.919V7.75H10.7367V9.919L10.5167 12.603H9.47175L9.25175 9.919ZM9.21875 13.439H10.7807V14.935H9.21875V13.439Z" fill="#F1C21B"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.875C10.2332 0.875 10.4469 1.00479 10.5545 1.21165L18.6795 16.8367C18.7803 17.0304 18.7726 17.2627 18.6592 17.4494C18.5459 17.636 18.3434 17.75 18.125 17.75H1.875C1.65662 17.75 1.45408 17.636 1.34075 17.4494C1.22742 17.2627 1.21974 17.0304 1.32049 16.8367L9.44549 1.21165C9.55306 1.00479 9.76684 0.875 10 0.875ZM2.90445 16.5H17.0956L10 2.85471L2.90445 16.5Z" fill="#F1C21B"></path>
              </svg>
                <h4 class="sdds-message-single">
                  {t("no-project-assigned-info")}
                </h4>
          </div>
        ) : (
          ""
        )}
        <div className="align-right sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
          <sdds-textfield
            type="text"
            size="sm"
            state="default"
            label={t("search-client")}
            label-position="inside"
            no-min-width
            placeholder={t("search-client")}
            value={searchClientVal}
            onInput={handleInputChange}
          >
            <span slot="sdds-prefix">
              <img src={searchIcon} className="icon-btn" />
            </span>
          </sdds-textfield>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("clientUsername")}
              >
                {t("client-name")}
                <img
                  src={
                    sortConfig?.key == "clientUsername" &&
                    getClassName("clientUsername") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "clientUsername" &&
                    getClassName("clientUsername") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("project")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("topic")}
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("access")}
              >
                {t("access")}
                <img
                  src={
                    sortConfig?.key == "access" &&
                    getClassName("access") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "access" &&
                    getClassName("access") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("authType")}
              >
                {t("auth-type")}
                <img
                  src={
                    sortConfig?.key == "authType" &&
                    getClassName("authType") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "authType" &&
                    getClassName("authType") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.clientId}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.clientUsername}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {getProjectName(value.projectId)}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {getTopicName(value.topicId)}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.access == "publishSubscribe"
                      ? "Publish and Subscribe"
                      : value.access == "publish"
                      ? "Publish"
                      : value.access == "subscribe"
                      ? "Subscribe"
                      : ""}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.authType == "clientCertificate"
                      ? "Client Certificate"
                      : value.authType == "basic"
                      ? "Basic Authentication"
                      : ""}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                    {context.session.userRole != "mqtt_mgmt_projectuser" ? (
                      <div className="div-btn-horz">
                        <button
                          disabled={value.status == "deactive"}
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editClient(value.clientId)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            deleteClientFunc(
                              value.clientId,
                              value.clientUsername
                            )
                          }
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                        <div className="sdds-toggle" tabindex="0">
                          <input
                            type="checkbox"
                            className="sdds-toggle-input"
                            checked={getStatus(value.status)}
                            onChange={() =>
                              handleCheckboxChange(
                                getStatus(value.status),
                                value.clientId,
                                value.clientUsername
                              )
                            }
                          />
                          <span className="sdds-toggle-switch"></span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={clientArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />

      <ClientUserModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={closeModal}
        getAllClientsFunc={getAllClientsFunc}
        getTopicsFunc={getTopicsFunc}
        projectArr={projectArr}
        topicArr={topicArr}
        projectIdBasedOnRole={projectIdBasedOnRole}
        showErrorModal={showErrorModal}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};

export default ClientUser;
