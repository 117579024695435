import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import ControlCenterModal from "./Modals/ControlCenterModal.tsx";
import CreateControlCenterModal from "./Modals/CreateControlCenterModal.tsx";
import { PageSize } from "../Constants.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";
import searchIcon from "../Assets/search.svg";
import {
  getAllControls,
  updateControl,
  actDeactControl,
  deleteControl,
  getAllProjects,
  getAllRoles,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const ControlCenter = () => {
  const { t } = useTranslation();
  let formDataInit = {
    username: { value: "", isChanged: false },
    projectId: { value: "", isChanged: false },
    superAdmin: { value: false, isChanged: false },
    roleId: { value: "", isChanged: false },
    controlCenterId: { value: "", isChanged: false },
  };

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [selectedControl, setSelectedControl] = useState("");
  const [selectedControlId, setSelectedControlId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [controlArr, setControlArr] = useState([]);
  const [filterControlArr, setfilterControlArr] = useState([]);
  const [projectArr, setProjectArr] = useState([]);
  const [roleArr, setRoleArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(controlArr);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const context = useContext(LoggedInContext);
  const [projectIdBasedOnRole, setProjectIdBasedOnRole] = useState([]);
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [searchUserVal, setSearchUserVal] = useState("");
  const [usernameVal, setUsernameVal] = useState("");
  const [actDeactStatus, setActDeactStatus] = useState("");

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  //Handling search input
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchUserVal(value);
    let filterArray = filterControlArr.filter((user: any) =>
      user.username.toLowerCase().includes(value.toLowerCase())
    );
    setControlArr(filterArray);
  };

  // function to activate/deactivate Control center user
  const handleCheckboxChange = (
    status,
    controlId: Number,
    projectId: Number,
    userName: string
  ) => {
    setActDeactOrDelete("actDeact");
    setSelectedControlId(controlId);
    setSelectedProjectId(projectId);
    setUsernameVal(userName);
    setActDeactStatus(status);
    if (status) {
      setWarningMessage(
        `${t("deactivate-user")} "${
          controlArr[
            controlArr.findIndex((x) => x.controlCenterId == controlId)
          ].username
        }" ?`
      );
    } else {
      setWarningMessage(
        `${t("active-user")} "${
          controlArr[
            controlArr.findIndex((x) => x.controlCenterId == controlId)
          ].username
        }" ?`
      );
    }
    document.getElementById("confirmationMessageModal").openModal();
  };

  const confirmedFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    let finalProjectId: any;
    if (selectedProjectId != null) {
      finalProjectId = selectedProjectId;
    } else {
      finalProjectId = 0;
    }
    setModeforResponseMessageModal("");
    if (actDeactOrDelete == "actDeact") {
      actDeactControl(selectedControlId, finalProjectId)
        .then((data: any) => {
          if (actDeactStatus) {
            setResponseMessage(
              `${t("control-center-user")} ${usernameVal} ${t(
                "deactive-success"
              )}`
            );
          } else {
            setResponseMessage(
              `${t("control-center-user")} ${usernameVal} ${t(
                "active-success"
              )}`
            );
          }
          document.getElementById("responseMessageModal").openModal();
          getAllControlsFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteControl(selectedControlId, finalProjectId)
        .then((data: any) => {
          setResponseMessage(
            `${t("control-center-user")} ${usernameVal} ${t("delete-success")}`
          );
          document.getElementById("responseMessageModal").openModal();
          getAllControlsFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on status 500
  const showErrorModal = (message) => {
    document.getElementById("modal-prog-control").closeModal();
    setResponseMessage(message);
    document.getElementById("responseMessageModal").openModal();
    setModeforResponseMessageModal("error");
  };

  const getStatus = (status) => {
    return status == "active" ? true : false;
  };

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  //Function to create new Control
  const addNew = () => {
    setFormDataObj(formDataInit);
    document.getElementById("new-modal-prog-control").openModal();
  };

  //Functoin to close Edit modal
  const closeModal = () => {
    document.getElementById("modal-prog-control").closeModal();
  };

  //Function to close the create modal
  const createCloseModal = () => {
    document.getElementById("new-modal-prog-control").closeModal();
  };

  const editUser = (id, projectId, roleId) => {
    let selectedUser =
      controlArr[
        controlArr.findIndex(
          (x) =>
            x.controlCenterId == id &&
            x.projectId == projectId &&
            x.roleId == roleId
        )
      ];
    let selectedUserDetails = {};
    for (let key in formDataInit) {
      selectedUserDetails[key] = { value: selectedUser[key], isChanged: false };
    }

    setFormDataObj((prevData) => ({
      ...selectedUserDetails,
      ["newOrEdit"]: { value: "edit" },
    }));
    setShowModal(!showModal);

    document.getElementById("modal-prog-control").openModal();
  };

  //Function to send selected Client details for delete to Confirmation modal
  const deleteControlFunc = (id: any, projectId: any, userName: string) => {
    setSelectedControl(
      controlArr[controlArr.findIndex((x) => x.controlCenterId == id)]
    );
    setActDeactOrDelete("delete");
    setSelectedControlId(id);
    setSelectedProjectId(projectId);
    setUsernameVal(userName);
    setWarningMessage(
      `${t("delete-user")} "${
        controlArr[controlArr.findIndex((x) => x.controlCenterId == id)]
          .username
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  // Function to delete the client
  const confirmDelete = (id: any) => {
    setShowDeleteModal(!showDeleteModal);
    deleteControl(id)
      .then((data: any) => {
        getAllControlsFunc();
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  // API call to get all clients
  const getAllControlsFunc = () => {
    getAllControls()
      .then((data: any) => {
        let finalArr = [];
        let filterArr = data.data.filter((x) => x.ccProjRole.length > 0);
        if (filterArr.length > 0) {
          if (
            context.session.projectCode != "" &&
            context.session.projectCode != null
          ) {
            for (let projSelected of projectIdBasedOnRole) {
              let filteredArray = data.data.filter(
                (x) => x.ccProjRole[0].projectId == projSelected
              );

              for (let dataObj of filteredArray) {
                for (let ccObj of dataObj.ccProjRole) {
                  let finalObj: any = {};
                  finalObj["username"] = dataObj.username;
                  finalObj["superAdmin"] = dataObj.superAdmin;
                  finalObj["roleId"] = ccObj.roleId;
                  finalObj["projectId"] = ccObj.projectId;
                  finalObj["status"] = ccObj.status;
                  finalObj["controlCenterId"] = dataObj.id;
                  finalArr.push(finalObj);
                }
              }
            }
            setControlArr(finalArr);
            setfilterControlArr(finalArr);
          } else {
            for (let dataObj of data.data) {
              for (let ccObj of dataObj.ccProjRole) {
                let finalObj: any = {};
                finalObj["username"] = dataObj.username;
                finalObj["superAdmin"] = dataObj.superAdmin;
                finalObj["roleId"] = ccObj.roleId;
                finalObj["projectId"] = ccObj.projectId;
                finalObj["status"] = ccObj.status;
                finalObj["controlCenterId"] = dataObj.id;
                finalArr.push(finalObj);
              }
            }
            setControlArr(finalArr);
            setfilterControlArr(finalArr);
          }
        } else {
          setControlArr(filterArr);
          setfilterControlArr(filterArr);
        }

        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  //API call to get all the projects
  const getAllProjectsFunc = () => {
    getAllProjects()
      .then((data) => {
        if (
          context.session.projectCode != "" &&
          context.session.projectCode != null &&
          context.session.projectCode != undefined
        ) {
          let finalProjArr = [];
          let selectedProjArr = [];
          for (let projCode of context.session.projectCode) {
            let projId =
              data.data[
                data.data.findIndex(
                  (element) => element.projectCode == projCode
                )
              ].projectId;
            finalProjArr.push(projId);
            for (let selectedProjObj of data.data) {
              if (selectedProjObj.projectId == projId) {
                selectedProjArr.push(selectedProjObj);
              }
            }
          }
          setProjectIdBasedOnRole(finalProjArr);
          setProjectArr(selectedProjArr);
        } else {
          setProjectArr(data.data);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  useEffect(() => {
    getAllProjectsFunc();
    getAllRoles()
      .then((data) => {
        let filterArr = data.data.filter(
          (x) => x.roleCode != "mqtt_mgmt_admin"
        );
        setRoleArr(filterArr);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
    getAllControlsFunc();
  }, [projectIdBasedOnRole.length]);

  const getProjectName = (projectId: any) => {
    let val = projectArr.findIndex((x) => x.projectId == projectId);
    return val >= 0 ? projectArr[val].projectName : "";
  };

  const getRoleName = (roleId: any) => {
    let val = roleArr.findIndex((x) => x.roleId == roleId);
    return val > -1 ? roleArr[val].roleName : "";
  };

  return (
    <>
      <div
        className={
          context.session.userRole != "mqtt_mgmt_projectuser"
            ? "search-align sdds-u-mb1"
            : "sdds-u-mb1"
        }
      >
        {context.session.userRole != "mqtt_mgmt_projectuser" ? (
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-block "
            onClick={addNew}
          >
            {t("create-control-center-user")}
          </button>
        ) : (
          ""
        )}
        <div className="align-right sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
          <sdds-textfield
            type="text"
            size="sm"
            state="default"
            label={t("search-user")}
            label-position="inside"
            no-min-width
            placeholder={t("search-user")}
            value={searchUserVal}
            onInput={handleInputChange}
          >
            <span slot="sdds-prefix">
              <img src={searchIcon} className="icon-btn" />
            </span>
          </sdds-textfield>
        </div>
      </div>
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("username")}
              >
                {t("username")}
                <img
                  src={
                    sortConfig?.key == "username" &&
                    getClassName("username") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "username" &&
                    getClassName("username") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("superAdmin")}
              >
                {t("is-superuser")}
                <img
                  src={
                    sortConfig?.key == "superAdmin" &&
                    getClassName("superAdmin") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "superAdmin" &&
                    getClassName("superAdmin") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("role")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("project")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.id}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.username}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.superAdmin ? "True" : "False"}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {getRoleName(value.roleId)}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {getProjectName(value.projectId)}
                  </td>

                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                    {context.session.userRole != "mqtt_mgmt_projectuser" ? (
                      <div className="div-btn-horz">
                        <button
                          disabled={value.status == "deactive"}
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            editUser(
                              value.controlCenterId,
                              value.projectId,
                              value.roleId
                            )
                          }
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            deleteControlFunc(
                              value.controlCenterId,
                              value.projectId,
                              value.username
                            )
                          }
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                        <div className="sdds-toggle" tabindex="0">
                          <input
                            type="checkbox"
                            className="sdds-toggle-input"
                            checked={getStatus(value.status)}
                            onChange={() =>
                              handleCheckboxChange(
                                getStatus(value.status),
                                value.controlCenterId,
                                value.projectId,
                                value.username
                              )
                            }
                          />
                          <span className="sdds-toggle-switch"></span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={controlArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <CreateControlCenterModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={createCloseModal}
        getAllControlsFunc={getAllControlsFunc}
        projectArr={projectArr}
        roleArr={roleArr}
        projectIdBasedOnRole={projectIdBasedOnRole}
        showErrorModal={showErrorModal}
      />
      <ControlCenterModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={closeModal}
        getAllControlsFunc={getAllControlsFunc}
        projectArr={projectArr}
        roleArr={roleArr}
        projectIdBasedOnRole={projectIdBasedOnRole}
        showErrorModal={showErrorModal}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};
export default ControlCenter;
