import React, { useState, useEffect } from "react";
import Header from "./Layouts/Header.tsx";
import "./App.css";
import Sidebar from "./Layouts/Sidebar.tsx";
import Footer from "./Layouts/Footer.tsx";
import Dashboard from "./Pages/Dashboard.tsx";
import useMediaQuery from "./Hooks/useMediaQuery.tsx";
import Login from "./Layouts/Login.tsx";
import { LoggedInContext } from "./Context/Context.tsx";
import { Buffer } from "buffer";
import { getByIdControls, getByIdProjects } from "./Services/Service.tsx";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { authProvider } from "./authProvider.tsx";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [sideMenuOnMob, setSideMenuOnMob] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [username, setUsername] = useState("");
  const [session, setSession] = useState({
    loggedin: false,
    username: "",
    userRole: "",
    projectCode: [],
  });

  useEffect(() => {
    const token = localStorage.getItem(
      "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
    );
    let decodedVal = Buffer.from(token, "base64").toString("binary");
    let jsonObj = JSON.parse(decodedVal.split("}")[1] + "}");
    setUsername(jsonObj["preferred_username"])
    setSession({
      ["username"]: jsonObj["preferred_username"],
      ["loggedin"]: true,
      ["projectCode"]: [],
      ["userRole"]: "",
    });
  }, []);
  const value = { session, setSession };

  const selectedProject = (project:any) =>{
    if(project == "All"){
      setSession({
        ["username"]: username,
        ["loggedin"]: true,
        ["projectCode"]: ["All"],
        ["userRole"]: "superadmin",
      });
    }else{
      setSession({
        ["username"]: username,
        ["loggedin"]: true,
        ["projectCode"]: [project.projectCode],
        ["userRole"]: project.role,
      });
    }
  }


  useEffect(() => {
    if (navigator.language.startsWith("pt")) {
      i18n.changeLanguage("pt");
    } else if (navigator.language.startsWith("zh")) {
      i18n.changeLanguage("zh");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const mobileMenuClick = (isOpen: boolean) => {
    setSideMenuOnMob(!isOpen);
  };
  const loadPage = (menu: any) => {
    setSelectedMenu(menu);
  };

  const logoutFunc = (value: string) => {
    setSession({
      ["username"]: "",
      ["loggedin"]: false,
      ["projectCode"]: [],
      ["userRole"]: "",
    });
    localStorage.clear();
    window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
  };

  return (
    <AzureAD provider={authProvider}>
      <BrowserRouter>
        <div className="App">
          <nav className="sdds-nav sdds-nav__sidemenu">
            <Header
              isDesktop={isDesktop}
              mobileMenuClick={mobileMenuClick}
              logoutFunc={logoutFunc}
              session={session.loggedin}
              username={username}
              selectedProjectFunc={selectedProject}
            />
          </nav>

          <div className="sdds-push sdds-push-fixed-height">
            <Sidebar onMobViewMenu={sideMenuOnMob} loadPage={loadPage} />
            <div className="sdds-container max-wd-100 overflow-auto">
              <>
                <LoggedInContext.Provider value={value}>
                  <Routes>
                    <Route
                      path="/login"
                      element={<Navigate to="/dashboard" />}
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <Dashboard
                          selectedMenu={selectedMenu}
                          loadPage={loadPage}
                        />
                      }
                    />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                  </Routes>
                </LoggedInContext.Provider>
              </>
            </div>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </AzureAD>
  );
};

export default App;
