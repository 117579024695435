import React, { useState, useEffect, useContext } from "react";
import {
  getAllTopics,
  updateTopic,
  createTopic,
  deleteTopic,
} from "../../Services/Service.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const TopicModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllTopicsFunc,
  projectArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [validationError, setValidationError] = useState("");
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const [errors, setErrors] = useState({});
  const context = useContext(LoggedInContext);

  const closeModalFunc = () => {
    closeModal();
  };

  useEffect(() => {
    setFormData(formDataObj);
  }, [formDataObj]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "projectId") {
      getProjectCode(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    if (formData.topicName.value && formData.projectId.value) {
      const topicData = {
        projectId: formData.projectId.value,
        topicDesc: formData.topicDesc.value,
      };
      if (formData.newOrEdit?.value == "edit") {
        const existingTopicData = {
          ...topicData,
          updatedBy: context.session.username,
          topicId: formData.topicId.value,
          topicName: formData.topicName.value,
        };
        //API call for update an existing role
        updateTopic(existingTopicData)
          .then((data: any) => {
            closeModal();
            getAllTopicsFunc();
          })
          .catch((err) => {
            if (err?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        const newTopicData = {
          ...topicData,
          createdBy: context.session.username,
          topicName: formData.topicName.value,
          status: true,
        };
        // API call for create new role
        createTopic(newTopicData)
          .then((data: any) => {
            closeModal();
            getAllTopicsFunc();
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
            if (error?.response?.data?.status == 500) {
              showErrorModal(`${t('topic')} ${newTopicData.topicName} ${t('exists')}`);
            }
          });
      }
    } else {
      console.log("error");
    }
  };

  // get project code when project name is selected
  const getProjectCode = (projectId) => {
    if (projectId != "") {
      let value = projectArr.findIndex((x) => x.projectId == projectId);
      setFormData((prevData) => ({
        ...prevData,
        ["topicName"]: {
          value: value > -1 ? `${projectArr[value].projectCode}/` : "",
          isChanged: true,
        },
      }));
    }
  };

  return (
    <>
      <sdds-modal
        id="modal-prog-topic"
        size="xs"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {formData.newOrEdit?.value == "edit" ? t('edit-topic') : t('create-topic')}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside txt-bold">
                    {t('project')}
                  </span>
                  <select
                    name="projectId"
                    id="mySelect"
                    disabled={formData.newOrEdit?.value == "edit"}
                    onChange={handleInputChange}
                    value={formData.projectId.value}
                    className={`${
                      formData.projectId.isChanged && !formData.projectId.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t('select-option')}
                    </option>
                    {projectArr &&
                      projectArr.map((project: any) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.projectName}
                        </option>
                      ))}
                  </select>
                  {formData.projectId.isChanged && !formData.projectId.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t('select-project-error')}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    validationError ||
                    (formData.topicName.isChanged && !formData.topicName.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  placeholder={t('enter-topic-name')}
                  disabled={formData.newOrEdit?.value == "edit"}
                  className="sdds-on-white-bg txt-bold"
                  name="topicName"
                  value={formData.topicName.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('topic-name')}
                  </span>

                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.topicName.isChanged && !formData.topicName.value
                      ? t('topic-name-error')
                      : ""}
                    {validationError}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textarea
                  className="sdds-on-white-bg txt-bold"
                  label={t('topic-desc')}
                  label-position="outside"
                  placeholder={t('enter-topic-desc')}
                  name="topicDesc"
                  value={formData.topicDesc.value}
                  onInput={handleInputChange}
                ></sdds-textarea>
              </div>
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default TopicModal;
