import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ResponseModal = ({ message, mode = "" }) => {
  const { t } = useTranslation();

  const borderTopStyle = {
    borderTop: "1px solid grey",
    paddingRight: 0,
    paddingBottom: 0,
  };

  const borderStyle = {
    paddingRight: 0,
    paddingBottom: 0,
  };

  // function to close the modal
  const closeModalFunc = () => {
    document.getElementById("responseMessageModal").closeModal();
  };

  return (
    <>
      <sdds-modal
        size="xs"
        id="responseMessageModal"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {mode != "error" ? t('confirm-msg') : ""}
        </h5>
        <p
          slot="sdds-modal-body"
          style={mode != "error" ? borderTopStyle : borderStyle}
        >
          <div className="sdds-row content-center">
            <p>{message}</p>
          </div>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
        >
          {t('ok')}
        </button>
      </sdds-modal>
    </>
  );
};

export default ResponseModal;
