import React, { useState, useEffect, useContext } from "react";
import { LoggedInContext } from "../../Context/Context.tsx";
import { passwordPattern } from "../../Constants.tsx";
import plusIcon from "../../Assets/Plus.svg";
import trashIcon from "../../Assets/Minus.svg";
import { createControl, getByIdControls } from "../../Services/Service.tsx";
import { useTranslation } from "react-i18next";

const CreateControlCenterModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllControlsFunc,
  projectArr,
  roleArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [validationError, setValidationError] = useState("");
  const [validationProjectError, setValidationProjectError] = useState(false);
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);

  const [isChecked, setIsChecked] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const [errors, setErrors] = useState(false);
  const [projRoleArr, setProjRoleArr] = useState([
    {
      projectId: { value: "", isChanged: false },
      roleId: { value: "", isChanged: false },
    },
  ]);
  const [projArr, setProjArr] = useState(projectArr);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setProjRoleArr([
        {
          projectId: { value: "", isChanged: false },
          roleId: { value: "", isChanged: false },
        },
      ]);
    }
  };

  const closeModalFunc = () => {
    setProjRoleArr([
      {
        projectId: { value: "", isChanged: false },
        roleId: { value: "", isChanged: false },
      },
    ]);
    closeModal();
  };

  useEffect(() => {
    setValidationError("");
    setValidationProjectError(false);
    setErrors(false);
    setFormData(formDataObj);
    setIsChecked(formDataObj.superAdmin.value);
    setProjArr(projectArr);
  }, [formDataObj]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "username") {
      emailValidation(value);
    }
  };

  const emailValidation = (value) => {
    if (value != "" && !emailPattern.test(value)) {
      setValidationError(t('control-username-email-error'));
      return false;
    } else {
      setValidationError("");
      return true;
    }
  };

  const addNewProjRole = (event) => {
    event?.preventDefault();
    let copyField = [...projRoleArr];
    let newRow = {
      projectId: { value: "", isChanged: false },
      roleId: { value: "", isChanged: false },
    };
    copyField.push(newRow);
    setProjRoleArr(copyField);
  };

  const deleteProjRole = (event, index) => {
    event.preventDefault();
    let copyField = [...projRoleArr];
    copyField.splice(index, 1);
    setProjRoleArr(copyField);
  };

  const handleDropdown = (event, index) => {
    const { name, value } = event.target;
    if (name == "projectId") {
      for (let projRole of projRoleArr) {
        if (projRole.projectId.value == value) {
          setValidationProjectError(true);
          break;
        } else {
          setValidationProjectError(false);
        }
      }
    }
    if (!validationProjectError) {
      let copyField = [...projRoleArr];
      copyField[index][name]["value"] = value;
      copyField[index][name]["isChanged"] = true;
      setProjRoleArr(copyField);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidationError("");
    getByIdControls(formData.username.value)
      .then((data: any) => {
        if (data.data.length != 0) {
          for (let obj of data.data) {
            if (obj.superAdmin) {
              setErrors(true);
              showErrorModal(
                `${t('control-center-user')} ${formData.username.value} ${t('is-assigned-superadmin')}`
              );
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
    const updatedFormData = {};
    const updatedProjRole: any = [];
    for (let i = 0; i < projRoleArr.length; i++) {
      updatedProjRole[i] = {};
      for (let key in projRoleArr[i]) {
        updatedProjRole[i][key] = {};
        let obj = {
          value: projRoleArr[i][key]["value"],
          isChanged: true,
        };
        updatedProjRole[i][key] = obj;
      }
    }
    setProjRoleArr(updatedProjRole);

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    let whenSuperAdminSelected = isChecked
      ? true
      : projRoleArr[0].projectId.value && projRoleArr[0].roleId.value;
    if (
      formData.username.value &&
      emailValidation(formData.username.value) &&
      whenSuperAdminSelected &&
      !validationProjectError &&
      !errors
    ) {
      let finalProjRole = [];
      if (isChecked) {
        setProjRoleArr([
          {
            projectId: { value: "", isChanged: false },
            roleId: { value: "", isChanged: false },
          },
        ]);
      }
      for (let projRole of projRoleArr) {
        let finalObj: any = {};
        finalObj["projectId"] = projRole.projectId.value;
        finalObj["roleId"] = projRole.roleId.value;
        finalProjRole.push(finalObj);
      }
      const controlData = {
        username: formData.username.value,
        ccProjRole: finalProjRole,
        superAdmin: isChecked,
      };
      const newControlData = {
        ...controlData,
        createdBy: context.session.username,
        status: true,
      };
      // API call for create new user
      createControl(newControlData)
        .then((data: any) => {
          setProjRoleArr([
            {
              projectId: { value: "", isChanged: false },
              roleId: { value: "", isChanged: false },
            },
          ]);
          closeModal();
          getAllControlsFunc();
        })
        .catch((error: any) => {
          if (error?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
          if (error?.response?.data?.status == 500) {
            showErrorModal(
              `${t('control-center-user')} ${newControlData.username} ${t('exists')}`
            );
          }
        });
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <sdds-modal
        id="new-modal-prog-control"
        size="sm"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">{t('create-control-center-user')}</h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    validationError ||
                    (formData.username.isChanged && !formData.username.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  placeholder={t('enter-username')}
                  className="sdds-on-white-bg txt-bold"
                  name="username"
                  value={formData.username.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('user-name')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.username.isChanged && !formData.username.value
                      ? t('username-error')
                      : ""}
                    {validationError}
                  </span>
                </sdds-textfield>
              </div>
              {context.session?.userRole?.toLowerCase() !=
              "mqtt_mgmt_projectadmin" ? (
                <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                  <div className="sdds-checkbox-item">
                    <input
                      className="sdds-form-input"
                      type="checkbox"
                      name="cb-example"
                      id="cb-option-1"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className="sdds-form-label" for="cb-option-1">
                      {t('is-super-user')}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              {projRoleArr.map((item, index) => (
                <>
                  <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                      <span className="sdds-dropdown-label-outside">
                        {t('project-name')}
                      </span>
                      <select
                        name="projectId"
                        disabled={isChecked}
                        onChange={(event) => handleDropdown(event, index)}
                        value={item.projectId.value}
                        className={`${
                          !isChecked &&
                          item.projectId.isChanged &&
                          !item.projectId.value
                            ? "error-color-border"
                            : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {t('select-option')}
                        </option>
                        {projArr.map((value) => (
                          <option value={value.projectId}>
                            {value.projectName}
                          </option>
                        ))}
                      </select>
                      {!isChecked &&
                      item.projectId.isChanged &&
                      !item.projectId.value ? (
                        <span className="sdds-dropdown-helper error-color-text">
                          {t('select-project-error')}
                        </span>
                      ) : validationProjectError ? (
                        <span className="sdds-dropdown-helper error-color-text">
                          {t('project-selected-error')}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-5 sdds-col-md-5 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                      <span className="sdds-dropdown-label-outside">{t('role')}</span>
                      <select
                        name="roleId"
                        disabled={isChecked}
                        onChange={(event) => handleDropdown(event, index)}
                        value={item.roleId.value}
                        className={`${
                          !isChecked &&
                          item.roleId.isChanged &&
                          !item.roleId.value
                            ? "error-color-border"
                            : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {t('select-option')}
                        </option>
                        {context.session.userRole != "" &&
                        context.session.userRole != null &&
                        context.session.userRole == "mqtt_mgmt_projectadmin"
                          ? roleArr
                              .filter(
                                (item) =>
                                  item.roleCode?.toLowerCase().trim("") ==
                                    "pur" ||
                                  item.roleCode?.toLowerCase().trim("") == "par"
                              )
                              .map((value) => (
                                <option value={value.roleId}>
                                  {value.roleName}
                                </option>
                              ))
                          : roleArr.map((value) => (
                              <option value={value.roleId}>
                                {value.roleName}
                              </option>
                            ))}
                      </select>
                      {!isChecked &&
                      item.roleId.isChanged &&
                      !item.roleId.value ? (
                        <span className="sdds-dropdown-helper error-color-text">
                          {t('select-role-error')}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="txt-vertical-aln-center sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2">
                    <button
                      className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8 sdds-u-p1"
                      onClick={(event) => addNewProjRole(event)}
                    >
                      <img className="icon-btn-schema" src={plusIcon} />
                    </button>
                    {projRoleArr.length > 1 ? (
                      <button
                        fill="white"
                        className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8 sdds-u-p1"
                        onClick={(event) => deleteProjRole(event, index)}
                      >
                        <img
                          fill="white"
                          className="icon-btn-schema"
                          src={trashIcon}
                        />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ))}
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default CreateControlCenterModal;
