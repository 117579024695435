import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="sdds-footer">
      <div className="sdds-footer-main custom-footer-main">
        <div className="sdds-footer-main-brand custom-footer">
          <p className="sdds-u-float-left">
            {t("copyright")} &copy; {t("2023-scania")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
