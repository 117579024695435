import React, { useState, useEffect, useContext } from "react";
import {
  getAllProjects,
  updateProject,
  createProject,
  deleteProject,
} from "../../Services/Service.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const ProjectModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllProjectsFunc,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);
  const closeModalFunc = () => {
    closeModal();
  };

  useEffect(() => {
    setFormData(formDataObj);
  }, [formDataObj]);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "projectCode" || name == "projectName") {
      if (specialCharsRegex.test(value)) {
        setFormData((prevInputs) => ({
          ...prevInputs,
          [name]: {
            value: value,
            isChanged: true,
            errorMsg: t('special-char-error'),
          },
        }));
      } else {
        setFormData((prevInputs) => ({
          ...prevInputs,
          [name]: { value: value, isChanged: true, errorMsg: "" },
        }));
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    if (
      formData.projectName.value &&
      formData.projectCode.value &&
      formData.startDate.value
    ) {
      const projectData = {
        projectCode: formData.projectCode.value,
        projectDesc: formData.projectDesc.value,
        projectName: formData.projectName.value,
        startDate: new Date(formData.startDate.value).getTime(),
        endDate:
          formData.endDate.value != ""
            ? new Date(formData.endDate.value).getTime()
            : "",
      };
      if (formData.newOrEdit?.value == "edit") {
        const existingProjectData = {
          ...projectData,
          updatedBy: context.session.username,
          projectId: formData.projectId.value,
        };
        //API call for update an existing role
        updateProject(existingProjectData)
          .then((data: any) => {
            closeModal();
            getAllProjectsFunc();
          })
          .catch((err) => {
            if (err?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        const newProjectData = {
          ...projectData,
          createdBy: context.session.username,
          status: true,
        };
        // API call for create new role
        createProject(newProjectData)
          .then((data: any) => {
            closeModal();
            getAllProjectsFunc();
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
            if (error?.response?.data?.status == 500) {
              showErrorModal(
                `${t('project')} ${newProjectData.projectName} ${t('exists')}`
              );
            }
          });
      }
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <sdds-modal
        id="modal-prog-proj"
        size="xs"
        class={`sdds-modal-backdrop hydrated `}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {formData.newOrEdit?.value == "edit"
            ? t('edit-project')
            : t('create-project')}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    formData.projectCode.errorMsg ||
                    (formData.projectCode.isChanged &&
                      !formData.projectCode.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  disabled={formData.newOrEdit?.value == "edit"}
                  placeholder={t('enter-project-code')}
                  className="sdds-on-white-bg txt-bold"
                  name="projectCode"
                  value={formData.projectCode.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('project-code')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.projectCode.isChanged &&
                    !formData.projectCode.value
                      ? t('project-code-error')
                      : ""}
                    {formData.projectCode.errorMsg
                      ? formData.projectCode.errorMsg
                      : ""}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    formData.projectName.errorMsg ||
                    (formData.projectName.isChanged &&
                      !formData.projectName.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  disabled={formData.newOrEdit?.value == "edit"}
                  placeholder={t('enter-project-name')}
                  className="sdds-on-white-bg txt-bold"
                  name="projectName"
                  value={formData.projectName.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('project-name')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.projectName.isChanged &&
                    !formData.projectName.value
                      ? t('project-name-error')
                      : ""}
                    {formData.projectName.errorMsg
                      ? formData.projectName.errorMsg
                      : ""}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textarea
                  className="sdds-on-white-bg txt-bold"
                  label={t('project-desc')}
                  label-position="outside"
                  placeholder={t('enter-project-desc')}
                  name="projectDesc"
                  value={formData.projectDesc.value}
                  onInput={handleInputChange}
                ></sdds-textarea>
              </div>
              <div className="sdds-u-mt3 sdds-row">
                <div className="max-wd-100 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                  <sdds-datetime
                    state={
                      formData.startDate.isChanged && !formData.startDate.value
                        ? "error"
                        : "success"
                    }
                    type="date"
                    size="sm"
                    value={formData.startDate.value}
                    name="startDate"
                    onInput={handleInputChange}
                  >
                    <span slot="sdds-label" className="txt-vertical-aln-left">
                      {t('start-date')}
                    </span>
                    <span
                      slot="sdds-helper"
                      className="sdds-u-float-left font-weight-none "
                    >
                      {formData.startDate.isChanged && !formData.startDate.value
                        ? t('start-date-error')
                        : ""}
                    </span>
                  </sdds-datetime>
                </div>
                <div className="sdds-u-mt3 max-wd-100 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                  <sdds-datetime
                    type="date"
                    size="sm"
                    value={formData.endDate.value}
                    name="endDate"
                    onInput={handleInputChange}
                  >
                    <span slot="sdds-label" className="txt-vertical-aln-left">
                      {t('end-date')}
                    </span>
                  </sdds-datetime>
                </div>
              </div>
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default ProjectModal;
