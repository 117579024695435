import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import RoleModal from "./Modals/RoleModal.tsx";
import { PageSize } from "../Constants.tsx";
import {
  getAllRoles,
  updateRole,
  createRole,
  deleteRole,
  actDeactRole,
  deleteClient,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const RolePage = () => {
  const { t } = useTranslation();
  // Initial value
  let formDataInit = {
    roleName: { value: "", isChanged: false },
    roleCode: { value: "", isChanged: false },
    roleDesc: { value: "", isChanged: false },
    roleId: { value: "", isChanged: false },
  };
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [roleArr, setRoleArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(roleArr);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const context = useContext(LoggedInContext);
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");

  // function to activate/deactivate Topic
  const handleCheckboxChange = (status, roleId: Number) => {
    setActDeactOrDelete("actDeact");
    setSelectedRoleId(roleId);
    if (status) {
      setWarningMessage(
        `Are you sure you want to deactivate the role "${
          roleArr[roleArr.findIndex((x) => x.roleId == roleId)].roleName
        }", as associated users will also be deactivated ?`
      );
    } else {
      setWarningMessage(
        `Are you sure you want to activate the role "${
          roleArr[roleArr.findIndex((x) => x.roleId == roleId)].roleName
        }", as associated users will also be activated ?`
      );
    }
    document.getElementById("confirmationMessageModal").openModal();
  };

  const confirmedFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    setModeforResponseMessageModal("");
    if (actDeactOrDelete == "actDeact") {
      actDeactRole(selectedRoleId)
        .then((data: any) => {
          setResponseMessage(data.data.message);
          document.getElementById("responseMessageModal").openModal();
          getAllRolesFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteRole(selectedRoleId)
        .then((data: any) => {
          setResponseMessage(data.data.message);
          document.getElementById("responseMessageModal").openModal();
          getAllRolesFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on status 500
  const showErrorModal = (message) => {
    document.getElementById("modal-prog-role").closeModal();
    setResponseMessage(message);
    document.getElementById("responseMessageModal").openModal();
    setModeforResponseMessageModal("error");
  };

  const getStatus = (status) => {
    return status == "active" ? true : false;
  };

  const deleteRoleFunc = (id: any) => {
    setActDeactOrDelete("delete");
    setSelectedRoleId(id);
    setSelectedRole(roleArr[roleArr.findIndex((x) => x.roleId == id)]);
    setWarningMessage(
      `Are you sure want to delete the Role "${
        roleArr[roleArr.findIndex((x) => x.roleId == id)].roleName
      }"?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  // Add new Role function
  const addNew = () => {
    setFormDataObj(formDataInit);
    setShowModal(!showModal);
    document.getElementById("modal-prog-role").openModal();
  };
  const closeModal = () => {
    setShowModal(!showModal);
    document.getElementById("modal-prog-role").closeModal();
  };
  const closeResponseModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  // Function for editing a role
  const editRole = (roleId) => {
    let selectedRole = roleArr[roleArr.findIndex((x) => x.roleId == roleId)];
    let selectedRoleDetails = {};
    for (let key in formDataInit) {
      selectedRoleDetails[key] = { value: selectedRole[key], isChanged: false };
    }
    setFormDataObj((prevData) => ({
      ...selectedRoleDetails,
      ["newOrEdit"]: { value: "edit" },
    }));
    setShowModal(!showModal);
    document.getElementById("modal-prog-role").openModal();
  };

  // API call to get all roles
  const getAllRolesFunc = () => {
    getAllRoles()
      .then((data: any) => {
        setRoleArr(data.data);
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  useEffect(() => {
    getAllRolesFunc();
  }, []);

  return (
    <>
      {context.session.userRole == "mqtt_mgmt_admin" ? (
        <button
          disabled
          className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-mb1 sdds-u-block "
          onClick={addNew}
        >
          {t('create-role')}
        </button>
      ) : (
        ""
      )}
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("roleName")}
              >
                {t('role-name')}
                <img
                  src={
                    sortConfig?.key == "roleName" &&
                    getClassName("roleName") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "roleName" &&
                    getClassName("roleName") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("roleCode")}
              >
                {t('role-code')}
                <img
                  src={
                    sortConfig?.key == "roleCode" &&
                    getClassName("roleCode") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "roleCode" &&
                    getClassName("roleCode") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t('role-desc')}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t('action')}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.roleId}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.roleName}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.roleCode}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.roleDesc}
                  </td>
                  {/*  {context.session.userRole == "mqtt_mgmt_admin" ? (
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                      <div className="div-btn-horz">
                        <button
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editRole(value.roleId)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() => deleteRoleFunc(value.roleId)}
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                        <div className="sdds-toggle " tabindex="0">
                          <input
                            type="checkbox"
                            className="sdds-toggle-input"
                            checked={value.status}
                            checked={getStatus(value.status)}
                            onChange={() =>
                              handleCheckboxChange(
                                getStatus(value.status),
                                value.roleId
                              )
                            }
                          />
                          <span className="sdds-toggle-switch"></span>
                        </div>
                      </div>
                    </td>
                  ) : (
                    ""
                  )} */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={roleArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <RoleModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={closeModal}
        getAllRolesFunc={getAllRolesFunc}
        showErrorModal={showErrorModal}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};

export default RolePage;
