import React, { useState, useEffect, useContext } from "react";
import {
  getAllClients,
  updateClient,
  createClient,
  deleteClient,
} from "../../Services/Service.tsx";
import { sha256, sha224 } from "js-sha256";
import { LoggedInContext } from "../../Context/Context.tsx";
import { passwordPattern } from "../../Constants.tsx";
import { useTranslation } from "react-i18next";

const ClientUserModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllClientsFunc,
  getTopicsFunc,
  projectArr,
  topicArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [changeOldPasswordError, setChangeOldPasswordError] = useState("");
  const [errors, setErrors] = useState({});
  const [validationError, setValidationError] = useState("");
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);
  const [showChangePasswordTemplate, setShowChangePasswordTemplate] =
    useState(false);
  const [newAndOldMatchPassword, setNewAndOldMatchPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [newPasswordValidationError, setNewPasswordValidationError] =
    useState("");

  const formDataChangePasswordObj = {
    OldPassword: { value: "", isChanged: false },
    changeNewPassword: { value: "", isChanged: false },
    changeConfirmPassword: { value: "", isChanged: false },
  };
  const [changePasswordObj, setChangePasswordObj] = useState(
    formDataChangePasswordObj
  );

  const closeModalFunc = () => {
    //setFormData(formDataInit);
    closeModal();
  };

  useEffect(() => {
    setFormData(formDataObj);

    return () => {
      setShowChangePasswordTemplate(false);
      setChangePasswordObj(formDataChangePasswordObj);
      setPasswordError("");
      setChangePasswordError("");
      setNewPasswordValidationError("");
      setPasswordValidationError("");
    };
  }, [formDataObj]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "confirmPassword") {
      setConfirmPassword(value);
    }
    if (name == "clientUsername") {
      if (specialCharsRegex.test(value)) {
        setValidationError(t("clientname-specialchar-error"));
      } else {
        setValidationError("");
      }
    }
    if (name == "password") {
      passwordValidation(value);
    }
  };

  const passwordValidation = (value) => {
    if (
      formData.newOrEdit?.value != "edit" &&
      value != "" &&
      !passwordPattern.test(value)
    ) {
      setPasswordValidationError(t("paswd-validation-error"));
      return false;
    } else {
      setPasswordValidationError("");
      return true;
    }
  };

  const newPasswordValidation = (value) => {
    if (value != "" && !passwordPattern.test(value)) {
      setNewPasswordValidationError(t("paswd-validation-error"));
      return false;
    } else {
      setNewPasswordValidationError("");
      return true;
    }
  };

  const handleInputChangeForChangePassword = (event) => {
    const { name, value } = event.target;
    if (name == "changeNewPassword") {
      setNewAndOldMatchPassword("");
      newPasswordValidation(value);
    }
    setChangePasswordObj((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
  };

  const showChangePasswordTemplateFunc = () => {
    setShowChangePasswordTemplate(true);
  };

  // function to check old password and new password match
  const oldAndNewPasswordMatch = () => {
    if (!changePasswordObj.OldPassword.value) {
      setChangeOldPasswordError(t("old-paswrd-error"));
      return false;
    }
    if (changePasswordObj.OldPassword.value) {
      if (
        formData.password.value !=
        sha256(changePasswordObj.OldPassword.value + "slat_foo123")
      ) {
        setChangeOldPasswordError(t("paswrd-nomatch"));
        return false;
      }
      if (
        changePasswordObj.OldPassword.value ==
        changePasswordObj.changeNewPassword.value
      ) {
        setNewAndOldMatchPassword(t("old-new-same-paswd"));
        return false;
      } else {
        setChangeOldPasswordError("");
        return true;
      }
    }
  };

  const changePaaswordCondFunc = (changePaaswordCond) => {
    if (changePaaswordCond) {
      return (
        oldAndNewPasswordMatch() &&
        changePasswordObj.changeNewPassword.value &&
        newPasswordValidation(changePasswordObj.changeNewPassword.value)
      );
    } else {
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPasswordError("");
    setChangePasswordError("");
    setNewPasswordValidationError("");
    setPasswordValidationError("");
    const updatedFormData = {};
    const updatedChangePasswordFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    for (let key in changePasswordObj) {
      if (changePasswordObj.hasOwnProperty(key)) {
        updatedChangePasswordFormData[key] = {
          value: changePasswordObj[key]["value"],
          isChanged: true,
        };
      }
    }

    setFormData(updatedFormData);
    setChangePasswordObj(updatedChangePasswordFormData);
    let passwordCond =
      formData.authType.value == "basic" ||
      formData.authType.value == "clientCertificate"
        ? formData.password.value && passwordValidation(formData.password.value)
        : true;
    // condition to check if change password fields are shown
    let changePaaswordCond =
      formData.newOrEdit?.value == "edit" && showChangePasswordTemplate;

    if (
      formData.clientUsername.value &&
      formData.projectId.value &&
      formData.topicId.value &&
      formData.access.value &&
      formData.authType.value &&
      passwordCond &&
      changePaaswordCondFunc(changePaaswordCond)
    ) {
      // below conditions are to show error message for confirm passwords fields both in normal and change password template
      if (!formData.confirmPassword.value && !showChangePasswordTemplate) {
        setPasswordError(t("confim-paswd-error"));
      } else if (
        !showChangePasswordTemplate &&
        formData.password.value !== formData.confirmPassword.value
      ) {
        setPasswordError(t("confirm-paswd-nomatch"));
      } else if (
        changePaaswordCond &&
        !changePasswordObj.changeConfirmPassword.value
      ) {
        setChangePasswordError(t("confim-paswd-error"));
      } else if (
        changePaaswordCond &&
        changePasswordObj.changeConfirmPassword.value !=
          changePasswordObj.changeNewPassword.value
      ) {
        setChangePasswordError(t("confirm-paswd-nomatch"));
      } else {
        let clientData = {
          clientUsername: formData.clientUsername.value,
          projectId: formData.projectId.value,
          topicId: formData.topicId.value,
          access: formData.access.value,
          authType: formData.authType.value,
        };
        // below conditions are to set value for password and confirm password fields based on scenarios
        if (formData.newOrEdit?.value == "edit") {
          clientData = {
            ...clientData,
            password: !showChangePasswordTemplate
              ? formData.password.value
              : sha256(
                  changePasswordObj.changeNewPassword.value + "slat_foo123"
                ),
            confirmPassword: showChangePasswordTemplate
              ? sha256(
                  changePasswordObj.changeConfirmPassword.value + "slat_foo123"
                )
              : formData.confirmPassword.value,
          };
        }
        clientData = {
          ...clientData,
          password: sha256(formData.password.value + "slat_foo123"),
          confirmPassword: sha256(
            formData.confirmPassword.value + "slat_foo123"
          ),
        };
        if (formData.newOrEdit?.value == "edit") {
          const existingClientData = {
            ...clientData,
            updatedBy: context.session.username,
            clientId: formData.clientId.value,
            changePassword: showChangePasswordTemplate ? true : false,
            status: true,
          };
          //API call for update an existing role
          updateClient(existingClientData)
            .then((data: any) => {
              closeModal();
              if (context.session.projectCode != "All") {
                getTopicsFunc(projectIdBasedOnRole);
              } else {
                getAllClientsFunc();
              }
            })
            .catch((err) => {
              if (err?.response?.status == 401) {
                context.setSession({
                  ["username"]: "",
                  ["loggedin"]: false,
                  ["projectCode"]: [],
                  ["userRole"]: "",
                });
                localStorage.clear();
                window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
              }
            });
        } else {
          const newClientData = {
            ...clientData,
            createdBy: context.session.username,
            status: true,
            changePassword: false,
          };
          // API call for create new role
          createClient(newClientData)
            .then((data: any) => {
              closeModal();
              if (context.session.projectCode != "All") {
                getTopicsFunc(projectIdBasedOnRole);
              } else {
                getAllClientsFunc();
              }
            })
            .catch((error: any) => {
              if (error?.response?.status == 401) {
                context.setSession({
                  ["username"]: "",
                  ["loggedin"]: false,
                  ["projectCode"]: [],
                  ["userRole"]: "",
                });
                localStorage.clear();
                window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
              }
              if (error?.response?.data?.status == 500) {
                showErrorModal(
                  `${t("client")} ${newClientData.clientUsername} ${t(
                    "exists"
                  )}`
                );
              }
            });
        }
      }
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <sdds-modal
        id="modal-prog-client"
        size="xs"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {formData.newOrEdit?.value == "edit"
            ? t("edit-client")
            : t("create-client")}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    validationError ||
                    (formData.clientUsername.isChanged &&
                      !formData.clientUsername.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  disabled={formData.newOrEdit?.value == "edit"}
                  placeholder={t("enter-client-name")}
                  class="sdds-on-white-bg"
                  name="clientUsername"
                  value={formData.clientUsername.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t("client-name")}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.clientUsername.isChanged &&
                    !formData.clientUsername.value
                      ? t("client-name-error")
                      : ""}
                    {validationError}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div class="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span class="sdds-dropdown-label-outside">
                    {t("project")}
                  </span>
                  <select
                    name="projectId"
                    id="mySelect"
                    disabled={formData.newOrEdit?.value == "edit"}
                    onChange={handleInputChange}
                    value={formData.projectId.value}
                    className={`${
                      formData.projectId.isChanged && !formData.projectId.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t("select-option")}
                    </option>
                    {projectArr.map((value) => (
                      <option key={value.projectId} value={value.projectId}>
                        {value.projectName}
                      </option>
                    ))}
                  </select>
                  {formData.projectId.isChanged && !formData.projectId.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t("select-project-error")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">
                    {t("topic")}
                  </span>
                  <select
                    name="topicId"
                    id="mySelect"
                    disabled={
                      formData.newOrEdit?.value == "edit" ||
                      formData.projectId.value == ""
                    }
                    onChange={handleInputChange}
                    value={formData.topicId.value}
                    className={`${
                      formData.topicId.isChanged && !formData.topicId.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t("select-option")}
                    </option>
                    {topicArr.map(
                      (value) =>
                        value.projectId == formData.projectId.value && (
                          <option key={value.topicId} value={value.topicId}>
                            {value.topicName}
                          </option>
                        )
                    )}
                  </select>
                  {formData.topicId.isChanged && !formData.topicId.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t("select-topic-error")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">
                    {t("access")}
                  </span>
                  <select
                    name="access"
                    onChange={handleInputChange}
                    value={formData.access.value}
                    className={`${
                      formData.access.isChanged && !formData.access.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t("select-option")}
                    </option>
                    <option value="publish">{t("publish")}</option>
                    <option value="subscribe">{t("subscribe")}</option>
                    <option value="publishSubscribe">{t("pub-sub")}</option>
                  </select>
                  {formData.access.isChanged && !formData.access.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t("access-error")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-small">
                  <span className="sdds-dropdown-label-outside">
                    {t("authentication-type")}
                  </span>
                  <select
                    name="authType"
                    id="mySelect"
                    onChange={handleInputChange}
                    value={formData.authType.value}
                    className={`${
                      formData.authType.isChanged && !formData.authType.value
                        ? "error-color-border"
                        : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      {t("select-option")}
                    </option>
                    <option value="basic">{t("basic-auth")}</option>
                    <option value="clientCertificate">
                      {t("client-cert")}
                    </option>
                  </select>
                  {formData.authType.isChanged && !formData.authType.value ? (
                    <span className="sdds-dropdown-helper error-color-text">
                      {t("auth-type-error")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {formData.authType.value == "basic" ||
              formData.authType.value == "clientCertificate" ? (
                <>
                  <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <sdds-textfield
                      state={
                        (formData.password.isChanged &&
                          !formData.password.value) ||
                        passwordValidationError
                          ? "error"
                          : "success"
                      }
                      size="md"
                      placeholder={t("enter-password")}
                      disabled={formData.newOrEdit?.value == "edit"}
                      class="sdds-on-white-bg"
                      name="password"
                      value={formData.password.value}
                      onInput={handleInputChange}
                      type="password"
                    >
                      <span slot="sdds-label" className="txt-vertical-aln-left">
                        {t("password")}
                      </span>
                      <span slot="sdds-helper" className="font-weight-none ">
                        {formData.password.isChanged && !formData.password.value
                          ? t("password-error")
                          : passwordValidationError}
                      </span>
                    </sdds-textfield>
                  </div>
                  {formData.newOrEdit?.value != "edit" ? (
                    <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={passwordError ? "error" : "success"}
                        size="md"
                        placeholder={t("enter-confirm-paswd")}
                        disabled={
                          formData.newOrEdit?.value == "edit" &&
                          formData.basicOrClient?.value == "basic"
                        }
                        className="sdds-on-white-bg txt-bold"
                        name="confirmPassword"
                        value={formData.confirmPassword.value}
                        onInput={handleInputChange}
                        type="password"
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("confirm-paswd")}
                        </span>
                        <span slot="sdds-helper" className="font-weight-none ">
                          {passwordError}
                        </span>
                      </sdds-textfield>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {formData.newOrEdit?.value == "edit" &&
              !showChangePasswordTemplate ? (
                <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                  <button
                    className="sdds-btn sdds-btn-primary sdds-btn-sm sdds-u-float-left"
                    onClick={showChangePasswordTemplateFunc}
                  >
                    {t("change-paswd")}
                  </button>
                </div>
              ) : (
                ""
              )}
              {showChangePasswordTemplate ? (
                <>
                  <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <sdds-textfield
                      state={changeOldPasswordError ? "error" : "success"}
                      size="md"
                      placeholder={t("enter-old-paswd")}
                      class="sdds-on-white-bg"
                      name="OldPassword"
                      value={changePasswordObj.OldPassword.value}
                      onInput={handleInputChangeForChangePassword}
                      type="password"
                    >
                      <span slot="sdds-label" className="txt-vertical-aln-left">
                        {t("old-paswd")}
                      </span>
                      <span slot="sdds-helper" className="font-weight-none ">
                        {changeOldPasswordError}
                      </span>
                    </sdds-textfield>
                  </div>

                  <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <sdds-textfield
                      state={
                        (changePasswordObj.changeNewPassword.isChanged &&
                          !changePasswordObj.changeNewPassword.value) ||
                        newAndOldMatchPassword ||
                        newPasswordValidationError
                          ? "error"
                          : "success"
                      }
                      size="md"
                      placeholder={t("enter-new-paswd")}
                      class="sdds-on-white-bg"
                      name="changeNewPassword"
                      value={changePasswordObj.changeNewPassword.value}
                      onInput={handleInputChangeForChangePassword}
                      type="password"
                    >
                      <span slot="sdds-label" className="txt-vertical-aln-left">
                        {t("new-paswd")}
                      </span>
                      <span slot="sdds-helper" className="font-weight-none ">
                        {changePasswordObj.changeNewPassword.isChanged &&
                        !changePasswordObj.changeNewPassword.value
                          ? t("new-paswd-error")
                          : newPasswordValidationError
                          ? newPasswordValidationError
                          : newAndOldMatchPassword
                          ? newAndOldMatchPassword
                          : ""}
                      </span>
                    </sdds-textfield>
                  </div>

                  <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <sdds-textfield
                      state={changePasswordError ? "error" : "success"}
                      size="md"
                      placeholder={t("enter-confirm-paswd")}
                      className="sdds-on-white-bg txt-bold"
                      name="changeConfirmPassword"
                      value={changePasswordObj.changeConfirmPassword.value}
                      onInput={handleInputChangeForChangePassword}
                      type="password"
                    >
                      <span slot="sdds-label" className="txt-vertical-aln-left">
                        {t("confirm-paswd")}
                      </span>
                      <span slot="sdds-helper" className="font-weight-none ">
                        {changePasswordError}
                      </span>
                    </sdds-textfield>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t("cancel")}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t("submit")}
        </button>
      </sdds-modal>
    </>
  );
};

export default ClientUserModal;
