import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import TopicModal from "./Modals/TopicModal.tsx";
import { PageSize } from "../Constants.tsx";
import {
  getAllTopics,
  updateTopic,
  createTopic,
  deleteTopic,
  actDeactTopic,
  getAllProjects,
  getProjectsByprojectCode,
  getTopicsByProjectId,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";
import searchIcon from "../Assets/search.svg";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const TopicPage = () => {
  const { t } = useTranslation();
  let formDataInit = {
    topicName: { value: "", isChanged: false },
    topicDesc: { value: "", isChanged: false },
    projectId: { value: "", isChanged: false },
    topicId: { value: "", isChanged: false },
  };
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [topicArr, setTopicArr] = useState([]);
  const [filterTopicArr, setFilterTopicArr] = useState([]);
  const [projectArr, setProjectArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(topicArr);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const context = useContext(LoggedInContext);
  const [projectIdBasedOnRole, setProjectIdBasedOnRole] = useState([]);
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [searchTopicVal, setSearchTopicVal] = useState("");
  const [topicNameVal, setTopicNameVal] = useState("");
  const [actDeactStatus, setActDeactStatus] = useState("");

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  //Handling search input
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchTopicVal(value);
    let filterArray = filterTopicArr.filter((topic: any) =>
      topic.topicName.toLowerCase().includes(value.toLowerCase())
    );
    setTopicArr(filterArray);
  };

  // function to activate/deactivate Topic
  const handleCheckboxChange = (status, topicId: Number, topicName: string) => {
    setActDeactOrDelete("actDeact");
    setSelectedTopicId(topicId);
    setTopicNameVal(topicName);
    setActDeactStatus(status);
    if (status) {
      setWarningMessage(
        `${t("deactive-topic")} "${
          topicArr[topicArr.findIndex((x) => x.topicId == topicId)].topicName
        }" ${t("associate-clients-deactive")}`
      );
    } else {
      setWarningMessage(
        `${t("active-topic")} "${
          topicArr[topicArr.findIndex((x) => x.topicId == topicId)].topicName
        }" ${t("associate-clients-active")}`
      );
    }
    document.getElementById("confirmationMessageModal").openModal();
  };

  const confirmedFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    setModeforResponseMessageModal("");
    if (actDeactOrDelete == "actDeact") {
      actDeactTopic(selectedTopicId)
        .then((data: any) => {
          if (actDeactStatus) {
            setResponseMessage(
              `${t("topic")} ${topicNameVal} ${t("deactive-success")}`
            );
          } else {
            setResponseMessage(
              `${t("topic")} ${topicNameVal} ${t("active-success")}`
            );
          }
          document.getElementById("responseMessageModal").openModal();
          getAllTopicsFunc(projectIdBasedOnRole);
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteTopic(selectedTopicId)
        .then((data: any) => {
          document.getElementById("confirmationMessageModal").closeModal();
          if (data?.data?.http_status == 409) {
            setResponseMessage(
              `${t("topic")} ${topicNameVal} ${t("topic-delete-error")}`
            );
          } else {
            setResponseMessage(
              `${t("topic")} ${topicNameVal} ${t("delete-success")}`
            );
          }

          document.getElementById("responseMessageModal").openModal();
          getAllTopicsFunc(projectIdBasedOnRole);
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on status 500
  const showErrorModal = (message) => {
    document.getElementById("modal-prog-topic").closeModal();
    setResponseMessage(message);
    document.getElementById("responseMessageModal").openModal();
    setModeforResponseMessageModal("error");
  };

  const getStatus = (status) => {
    return status == "active" ? true : false;
  };

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  //Function to create new Project
  const addNew = () => {
    setFormDataObj(formDataInit);
    setShowModal(!showModal);
    document.getElementById("modal-prog-topic").openModal();
  };

  //Functoin to close Create or Edit modal
  const closeModal = () => {
    setShowModal(!showModal);
    document.getElementById("modal-prog-topic").closeModal();
  };

  //Function to close delete Confirmation Modal
  const closeResponseModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const editTopic = (topicId) => {
    let selectedTopic =
      topicArr[topicArr.findIndex((x) => x.topicId == topicId)];
    let selectedTopicDetails = {};
    for (let key in formDataInit) {
      selectedTopicDetails[key] = {
        value: selectedTopic[key],
        isChanged: false,
      };
    }

    setFormDataObj((prevData) => ({
      ...selectedTopicDetails,
      ["newOrEdit"]: { value: "edit" },
    }));
    setShowModal(!showModal);
    document.getElementById("modal-prog-topic").openModal();
  };
  //Function to send selected topic details for delete to Confirmation modal
  const deleteTopicFunc = (topicId: any, topicName: string) => {
    setSelectedTopic(topicArr[topicArr.findIndex((x) => x.topicId == topicId)]);
    setActDeactOrDelete("delete");
    setSelectedTopicId(topicId);
    setTopicNameVal(topicName);
    setWarningMessage(
      `${t("delete-topic")} "${
        topicArr[topicArr.findIndex((x) => x.topicId == topicId)].topicName
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  // Function to delete the role
  const confirmDelete = (topicId: any) => {
    setShowDeleteModal(!showDeleteModal);
    deleteTopic(topicId)
      .then((data: any) => {
        getAllTopicsFunc(projectIdBasedOnRole);
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  // API call to get all topics
  const getAllTopicsFunc = (projectId = "") => {
    const projectCode = context.session.projectCode;
    if (projectCode == "All") {
      getAllTopics()
        .then((data: any) => {
          setTopicArr(data.data);
          setFilterTopicArr(data.data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (projectCode != "" && projectCode != null) {
      getTopicsByProjectId(projectId)
        .then((data: any) => {
          setTopicArr(data.data);
          setFilterTopicArr(data.data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
    setCurrentPage(1);
  };

  // API call to get all projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects()
        .then((data: any) => {
          setProjectArr(data.data);
          getAllTopicsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getProjectsByprojectCode(context.session.projectCode)
        .then((data: any) => {
          setProjectIdBasedOnRole(data.data[0].projectId);
          setProjectArr(data.data);
          getAllTopicsFunc(data.data[0].projectId);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  useEffect(() => {
    getAllProjectsFunc();
  }, [context.session.projectCode]);

  const getProjectName = (projectId) => {
    let val = projectArr.findIndex((x) => x.projectId == projectId);
    return val > -1 ? projectArr[val].projectName : "";
  };

  return (
    <>
      <div
        className={
          context.session.userRole != "mqtt_mgmt_projectuser"
            ? "search-align sdds-u-mb1"
            : "sdds-u-mb1"
        }
      >
        {context.session.userRole != "mqtt_mgmt_projectuser" ? (
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-block "
            onClick={addNew}
            disabled={projectArr.length == 0}
          >
            {t("create-topic")}
          </button>
        ) : (
          ""
        )}
        {projectArr.length == 0 ? (
          <div class="sdds-message sdds-message__type-warning sdds-message__icon-active sdds-message__extended-active sdds-message-ongrey">
            <svg
              class="sdds-message-icon sdds-message-icon--warning"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25175 9.919V7.75H10.7367V9.919L10.5167 12.603H9.47175L9.25175 9.919ZM9.21875 13.439H10.7807V14.935H9.21875V13.439Z"
                fill="#F1C21B"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 0.875C10.2332 0.875 10.4469 1.00479 10.5545 1.21165L18.6795 16.8367C18.7803 17.0304 18.7726 17.2627 18.6592 17.4494C18.5459 17.636 18.3434 17.75 18.125 17.75H1.875C1.65662 17.75 1.45408 17.636 1.34075 17.4494C1.22742 17.2627 1.21974 17.0304 1.32049 16.8367L9.44549 1.21165C9.55306 1.00479 9.76684 0.875 10 0.875ZM2.90445 16.5H17.0956L10 2.85471L2.90445 16.5Z"
                fill="#F1C21B"
              ></path>
            </svg>
            <h4 class="sdds-message-single">{t("no-project-assigned-info")}</h4>
          </div>
        ) : (
          ""
        )}
        <div className="align-right sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-3 sdds-col-md-3 sdds-col-sm-3 sdds-col-xs-3">
          <sdds-textfield
            type="text"
            size="sm"
            state="default"
            label={t("search-topic")}
            label-position="inside"
            no-min-width
            placeholder={t("search-topic")}
            value={searchTopicVal}
            onInput={handleInputChange}
          >
            <span slot="sdds-prefix">
              <img src={searchIcon} className="icon-btn" />
            </span>
          </sdds-textfield>
        </div>
      </div>
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("topicName")}
              >
                {t("topic-name")}
                <img
                  src={
                    sortConfig?.key == "topicName" &&
                    getClassName("topicName") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "topicName" &&
                    getClassName("topicName") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("topic-desc")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("project")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.topicId}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.topicName}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.topicDesc}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {getProjectName(value.projectId)}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                    {context.session.userRole != "mqtt_mgmt_projectuser" ? (
                      <div className="div-btn-horz">
                        <button
                          disabled={value.status == "deactive"}
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editTopic(value.topicId)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            deleteTopicFunc(value.topicId, value.topicName)
                          }
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                        <div className="sdds-toggle" tabindex="0">
                          <input
                            type="checkbox"
                            className="sdds-toggle-input"
                            checked={getStatus(value.status)}
                            onChange={() =>
                              handleCheckboxChange(
                                getStatus(value.status),
                                value.topicId,
                                value.topicName
                              )
                            }
                          />
                          <span className="sdds-toggle-switch"></span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={topicArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <TopicModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={closeModal}
        getAllTopicsFunc={getAllTopicsFunc}
        projectArr={projectArr}
        projectIdBasedOnRole={projectIdBasedOnRole}
        showErrorModal={showErrorModal}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};

export default TopicPage;
