import React, { useState, useEffect, useContext } from "react";
import { sha256, sha224 } from "js-sha256";
import { LoggedInContext } from "../../Context/Context.tsx";
import { useTranslation } from "react-i18next";
import { specialCharsAndNumbersStartRestrictRegex } from "../../Constants.tsx";
import plusIcon from "../../Assets/Plus.svg";
import trashIcon from "../../Assets/Minus.svg";
import { createActionApi, updateActionApi } from "../../Services/Service.tsx";

const CreateRuleModal = ({
  formDataInitRepublish,
  formDataInitkafka,
  formDataInitInflux,
  sendConfigData,
  newOrEdit,
  actionType,
  cancelModal,
  isNewAction,
}) => {
  const { t } = useTranslation();
  const [selectedActionType, setSelectedActionType] = useState("");
  const [selectedTargetQos, setSelectedTargetQos] = useState("");
  const [selectedTargetRetain, setSelectedTargetRetain] = useState("");
  const [selectedKafkaEncode, setSelectedKafkaEncode] = useState("");
  const [selectedCompression, setSelectedCompression] = useState("");
  const [selectedPartStrategy, setSelectedPartStrategy] = useState("");
  const [formData, setFormData] = useState();
  const [duplicateFieldKeys, setDuplicateFieldKeys] = useState([]);
  const [duplicateTagKeys, setDuplicateTagKeys] = useState([]);
  const context = useContext(LoggedInContext);
  const [fieldKeyValidationError, setFieldKeyValidationError] = useState("");
  const [tagKeyValidationError, setTagKeyValidationError] = useState("");
  const specialCharsRegex = /^[^\d\W\s][\w\W\s]*$/;
  const specialCharsAndNumbersStartRestrictRegexForName =
    /^[A-Za-z][A-Za-z0-9_-]*$/;
  const specialCharsRegexForKafkaHeader = /^\${[A-Za-z0-9._]+}$/;
  let actionTypeArr = [
    {
      actionTypeId: 1,
      actionTypeName: t("republish"),
    },
    {
      actionTypeId: 2,
      actionTypeName: t("kafka"),
    },
    {
      actionTypeId: 3,
      actionTypeName: t("influx-db"),
    },
  ];

  let targetQosArr = [
    {
      targetQosId: 1,
      targetQosName: 0,
    },
    {
      targetQosId: 2,
      targetQosName: 1,
    },
    {
      targetQosId: 3,
      targetQosName: 2,
    },
    {
      targetQosId: 4,
      targetQosName: "${qos}",
    },
  ];

  let targetRetainArr = [
    {
      targetRetainValue: "true",
      targetRetainName: t("true"),
    },
    {
      targetRetainValue: "false",
      targetRetainName: t("false"),
    },
  ];

  let kafkaHeaderEncodeArr = [
    {
      kafkaHeaderEncodeId: 1,
      kafkaHeaderEncodeValue: "none",
      kafkaHeaderEncodeName: t("None"),
    },
    {
      kafkaHeaderEncodeId: 2,
      kafkaHeaderEncodeValue: "json",
      kafkaHeaderEncodeName: t("JSON"),
    },
  ];

  let partitionStrategyArr = [
    {
      partStrategyId: 1,
      partStrategyVal: "random",
      partStrategyName: t("random"),
    },
    {
      partStrategyId: 2,
      partStrategyVal: "key_dispatch",
      partStrategyName: t("key-dispatch"),
    },
  ];

  let compressionArr = [
    {
      compressionId: 1,
      compressionVal: "no_compression",
      compressionName: t("no-compression"),
    },
    {
      compressionId: 2,
      compressionVal: "snappy",
      compressionName: t("snappy"),
    },
    {
      compressionId: 3,
      compressionVal: "gzip",
      compressionName: t("gzip"),
    },
  ];

  const handleActionTypeChange = (event) => {
    const { name, value } = event.target;
    if (value == 1) {
      setFormData(formDataInitRepublish);
      setSelectedTargetQos(formDataInitRepublish.targetQos.value);
      setSelectedTargetRetain(formDataInitRepublish.targetRetain.value);
    } else if (value == 2) {
      setFormData(formDataInitkafka);
      setSelectedKafkaEncode(formDataInitkafka.kafkaHeaderValueEncode.value);
      setSelectedCompression(formDataInitkafka.compression.value);
      setSelectedPartStrategy(formDataInitkafka.partStrategy.value);
    } else if (value == 3) {
      setFormData(formDataInitInflux);
    }
    setSelectedActionType(value);
  };

  // validating input fields for republish action
  const validateInputsForRepublish = (name, value) => {
    let isValid = true;
    let errorMessage = "";
    if (name == "targetTopic" || name == "payloadTemplate") {
      // Check for empty field
      if (!value.trim()) {
        errorMessage = t("required-field-validation");
        isValid = false;
      }
    }
    return { isValid, errorMessage };
  };

  // validating input fields for influx db
  const validateInputsForInfluxDb = (name, value) => {
    let isValid = true;
    let errorMessage = "";
    if (
      name == "influxDatabase" ||
      name == "influxMeasurement" ||
      name == "influxTimestamp"
    ) {
      // Check for empty field
      if (!value.trim()) {
        errorMessage = t("required-field-validation");
        isValid = false;
      }
    } else if (name == "influxName" || name == "influxServerHost") {
      if (!value.trim()) {
        errorMessage = t("required-field-validation");
        isValid = false;
      } else if (
        name == "influxName" &&
        !specialCharsAndNumbersStartRestrictRegexForName.test(value)
      ) {
        errorMessage = t("error-message-special-charc-name");
        isValid = false;
      } else {
        if (!specialCharsRegex.test(value)) {
          errorMessage = t("error-message-special-charc");
          isValid = false;
        }
      }
    }

    return { isValid, errorMessage };
  };

  // validating input fields for influx db
  const validateInputsForKafka = (name, value) => {
    let isValid = true;
    let errorMessage = "";
    if (
      name == "description" ||
      name == "kafkaTopic" ||
      name == "timestamp" ||
      name == "messageKey" ||
      name == "messageVal"
    ) {
      // Check for empty field
      if (!value.trim()) {
        errorMessage = t("required-field-validation");
        isValid = false;
      }
    } else if (name == "name") {
      if (!value.trim()) {
        errorMessage = t("required-field-validation");
        isValid = false;
      } else {
        if (!specialCharsAndNumbersStartRestrictRegexForName.test(value)) {
          errorMessage = t("error-message-special-charc-name");
          isValid = false;
        }
      }
    } else if (
      value &&
      name == "kafkaHeaders" &&
      !specialCharsRegexForKafkaHeader.test(value)
    ) {
      errorMessage = t("error-message-special-charc-kafka-header");
      isValid = false;
    }
    return { isValid, errorMessage };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (selectedActionType == "1") {
      const validationResults = validateInputsForRepublish(name, value);
      let copyFormData = { ...(formData ? formData : formDataInitRepublish) };
      copyFormData[name]["value"] = value;
      copyFormData[name]["isChanged"] = true;
      copyFormData[name]["isValid"] = validationResults.isValid;
      copyFormData[name]["errorMessage"] = validationResults.errorMessage;
      setFormData(copyFormData);
    } else if (selectedActionType == "3") {
      const validationResults = validateInputsForInfluxDb(name, value);
      let copyFormData = { ...(formData ? formData : formDataInitInflux) };
      copyFormData[name]["value"] = value;
      copyFormData[name]["isChanged"] = true;
      copyFormData[name]["isValid"] = validationResults.isValid;
      copyFormData[name]["errorMessage"] = validationResults.errorMessage;
      setFormData(copyFormData);
    } else if (selectedActionType == "2") {
      const validationResults = validateInputsForKafka(name, value);
      let copyFormData = { ...(formData ? formData : formDataInitkafka) };
      copyFormData[name]["value"] = value;
      copyFormData[name]["isChanged"] = true;
      copyFormData[name]["isValid"] = validationResults.isValid;
      copyFormData[name]["errorMessage"] = validationResults.errorMessage;
      setFormData(copyFormData);
    } else if (name == "targetQos") {
      let valueQos = targetQosArr.findIndex((x) => x.targetQosId == value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          value: targetQosArr[valueQos].targetQosName,
          isChanged: true,
        },
      }));
      getTargetQos("", value);
    } else if (name == "kafkaHeaderValueEncode") {
      let valueEncode = kafkaHeaderEncodeArr.findIndex(
        (x) => x.kafkaHeaderEncodeId == value
      );
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          value: kafkaHeaderEncodeArr[valueEncode].kafkaHeaderEncodeValue,
          isChanged: true,
        },
      }));
      setSelectedKafkaEncode(value);
    } else if (name == "compression") {
      let valueComp = compressionArr.findIndex((x) => x.compressionId == value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          value: compressionArr[valueComp].compressionVal,
          isChanged: true,
        },
      }));
      setSelectedCompression(value);
    } else if (name == "partStrategy") {
      let valuePart = partitionStrategyArr.findIndex(
        (x) => x.partStrategyId == value
      );
      setFormData((prevData) => ({
        ...prevData,
        [name]: {
          value: partitionStrategyArr[valuePart].partStrategyVal,
          isChanged: true,
        },
      }));
      setSelectedPartStrategy(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: { value: value, isChanged: true },
      }));
      if (name == "targetRetain") {
        setSelectedTargetRetain(value);
      }
    }
  };

  // Function to set the value for header inputs on typing
  const handleInputChangeHeaders = (event, index) => {
    const { name, value } = event.target;
    let copyHeader = { ...formData };
    if (name == "fieldVal") {
      copyHeader.headerKeyValList.value[index][name] = value;
    } else {
      copyHeader.headerKeyValList.value[index][name] = value;
      if (
        value != "" &&
        !specialCharsAndNumbersStartRestrictRegex.test(value)
      ) {
        copyHeader.headerKeyValList.value[index]["isValid"] = false;
      } else {
        copyHeader.headerKeyValList.value[index]["isValid"] = true;
      }
      //   checkFieldKeyValidation();
    }
    copyHeader.headerKeyValList.value[index]["isChanged"] = true;
    setFormData(copyHeader);
  };

  const inputValidationForFields = (name, value) => {
    let isValid = true;
    let errorMessage = "";
    // Check for empty field
    if (!value.trim()) {
      errorMessage = t("required-field-validation");
      isValid = false;
    }

    return { isValid, errorMessage };
  };

  // Function to set the value for fields inputs on typing
  const handleInputChangeField = (event, index) => {
    const { name, value } = event.target;
    const validationResults = inputValidationForFields(name, value);
    let copyFormData = { ...(formData ? formData : formDataInitInflux) };
    copyFormData.fieldKeyValList.value[index][name] = value;
    copyFormData.fieldKeyValList.value[index]["isChanged"] = true;
    copyFormData.fieldKeyValList.value[index]["isValid"] =
      validationResults.isValid;
    copyFormData.fieldKeyValList.value[index]["errorMessage"] =
      validationResults.errorMessage;
    setFormData(copyFormData);
  };

  // Function to set the value for Tags inputs on typing
  const handleInputChangeTag = (event, index) => {
    const { name, value } = event.target;
    let copyTag = { ...formData };
    copyTag.tagKeyValList.value[index][name] = value;
    copyTag.tagKeyValList.value[index]["isChanged"] = true;
    setFormData(copyTag);
    if (name == "tagKey") {
      if (
        value != "" &&
        !specialCharsAndNumbersStartRestrictRegex.test(value)
      ) {
        copyTag.tagKeyValList.value[index]["isValid"] = false;
      } else {
        copyTag.tagKeyValList.value[index]["isValid"] = true;
      }
      //   checkTagKeyValidation();
    }
  };

  //Function to add new new header inputs on add button click
  const addNewHeader = (event) => {
    event?.preventDefault();
    let copyHeader = { ...formData };
    let newRow = {
      headerKey: "",
      headerVal: "",
      isChanged: false,
      isValid: true,
    };
    copyHeader.headerKeyValList.value.push(newRow);
    setFormData(copyHeader);
    // checkDuplicateFieldKeys();
  };

  //Function to delete particular field
  const deleteHeader = (event, index) => {
    event.preventDefault();
    let copyHeader = { ...formData };
    copyHeader.headerKeyValList.value.splice(index, 1);
    setFormData(copyHeader);
    // checkDuplicateFieldKeys();
  };

  //Function to add new new field inputs on add button click
  const addNewField = (event) => {
    event?.preventDefault();
    let copyField = { ...formData };
    let newRow = {
      fieldKey: "",
      fieldVal: "",
      isChanged: false,
      isValid: true,
    };
    copyField.fieldKeyValList.value.push(newRow);
    setFormData(copyField);
    // checkDuplicateFieldKeys();
  };

  //Function to delete particular field
  const deleteField = (event, index) => {
    event.preventDefault();
    let copyField = { ...formData };
    copyField.fieldKeyValList.value.splice(index, 1);
    setFormData(copyField);
    // checkDuplicateFieldKeys();
  };

  //Function to add new new tag inputs on add button click
  const addNewTag = (event) => {
    event?.preventDefault();
    // checkDuplicateTagKeys();
    let copyTag = { ...formData };
    let newRow = {
      tagKey: "",
      tagVal: "",
      isChanged: false,
      isValid: true,
    };
    copyTag.tagKeyValList.value.push(newRow);
    setFormData(copyTag);
  };

  //Function to delete particular Tag
  const deleteTag = (event, index) => {
    event.preventDefault();
    // checkDuplicateTagKeys();
    let copyTag = { ...formData };
    copyTag.tagKeyValList.value.splice(index, 1);
    setFormData(copyTag);
  };

  const checkFieldsValidation = (fieldsValArr: any) => {
    let tempFieldsValArr = fieldsValArr.value.map((field: any, index: any) => {
      if (!field.fieldKey || !field.fieldVal) {
        return {
          ...field,
          isValid: false,
          errorMessage: t("required-field-validation"),
        };
      } else {
        return {
          ...field,
          isValid: true,
          errorMessage: "",
        };
      }
    });
    return tempFieldsValArr;
  };

  const checkValidation = (actionType, data) => {
    if (actionType == "republish") {
      let updatedFormData = {};
      for (let key in data) {
        if (key == "targetTopic" || key == "payloadTemplate") {
          let validateRespose = validateInputsForRepublish(
            key,
            formData[key]["value"]
          );
          updatedFormData[key] = {
            ...data[key],
            isValid: validateRespose.isValid,
            errorMessage: validateRespose.errorMessage,
          };
        } else {
          updatedFormData[key] = {
            ...data[key],
          };
        }
      }
      setFormData(updatedFormData);
      let isEntireObjectValid = Object.values(updatedFormData).every(
        (obj) => obj.isValid
      );
      return isEntireObjectValid;
    } else if (actionType == "kafka") {
      let updatedFormData = {};
      for (let key in data) {
        if (
          key != "headerKeyValList" &&
          key != "partStrategy" &&
          key != "compressioncompression" &&
          key != "kafkaHeaderValueEncode"
        ) {
          let validateRespose = validateInputsForKafka(
            key,
            formData[key]["value"]
          );
          updatedFormData[key] = {
            ...data[key],
            isValid: validateRespose.isValid,
            errorMessage: validateRespose.errorMessage,
          };
        } else {
          updatedFormData[key] = {
            ...data[key],
          };
        }
      }
      setFormData(updatedFormData);
      let isEntireObjectValid = Object.values(updatedFormData).every(
        (obj) => obj.isValid
      );
      return isEntireObjectValid;
    } else if (actionType == "influxdb") {
      let updatedFormData = {};
      for (let key in data) {
        if (key != "fieldKeyValList" && key != "tagKeyValList") {
          let validateRespose = validateInputsForInfluxDb(
            key,
            formData[key]["value"]
          );
          updatedFormData[key] = {
            ...data[key],
            isValid: validateRespose.isValid,
            errorMessage: validateRespose.errorMessage,
          };
        } else if (key == "fieldKeyValList") {
          updatedFormData[key] = {
            value: checkFieldsValidation(formData[key]),
          };
        } else {
          updatedFormData[key] = {
            ...data[key],
          };
        }
      }
      setFormData(updatedFormData);
      let isEntireObjectValid = isAllValid(updatedFormData);
      return isEntireObjectValid;
    }
  };

  function isAllValid(obj) {
    // Base case: If the current object has an `isValid` property
    if (obj.hasOwnProperty("isValid") && !obj.isValid) {
      return false;
    }

    // Iterate through all properties of the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // If the property is an object, recurse
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (!isAllValid(obj[key])) {
            return false;
          }
        }
      }
    }

    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let finalData = {};
    let actionData = [];
    if (selectedActionType == "1") {
      if (checkValidation("republish", formData)) {
        finalData = {
          args: {
            payload: formData.payloadTemplate.value,
            qos: formData.targetQos.value,
            retain: formData.targetRetain.value == "true" ? true : false,
            topic: formData.targetTopic.value,
            mqtt_properties: {},
            user_properties: "",
          },
          function: "republish",
        };
        actionData.push(finalData);
        sendConfigData(actionData);
      } else {
        return;
      }
    } else if (selectedActionType == "2") {
      if (checkValidation("kafka", formData)) {
        let headerArr = [];
        formData.headerKeyValList.value.map((headers) => {
          let headerObj = {};
          if (headers.headerKey != "") {
            headerObj[headers.headerKey] = headers.headerVal;
            headerArr.push(headerObj);
          }
        });
        let config_data = {
          description: formData.description.value,
          name: formData.name.value,
          topic: formData.kafkaTopic.value,
          kafka_header_value_encode_mode: formData.kafkaHeaderValueEncode.value,
          compression: formData.compression.value,
          partition_strategy: formData.partStrategy.value,
          key: formData.messageKey.value,
          value: formData.messageVal.value,
          timestamp: formData.timestamp.value,
        };
        if (formData.kafkaHeaders.value != "") {
          config_data["kafka_headers"] = formData.kafkaHeaders.value;
        }
        if (headerArr.length > 0) {
          config_data["kafka_ext_header"] = JSON.stringify(headerArr);
        }
        finalData = {
          config_type: "kafka",
          configuration: JSON.stringify(config_data),
        };
        createUpdateActionApi(finalData, actionData);
      } else {
        return;
      }
    } else if (selectedActionType == "3") {
      if (checkValidation("influxdb", formData)) {
        let fieldsVal = "";
        let tagsVal = "";
        formData.fieldKeyValList.value.map((fields) => {
          fieldsVal += fields.fieldKey + "=" + fields.fieldVal + ",";
        });
        formData.tagKeyValList.value.map((tags) => {
          tagsVal += tags.tagKey + "=" + tags.tagVal + ",";
        });
        if (tagsVal == "=,") {
          tagsVal = "";
        } else {
          tagsVal = "," + tagsVal.slice(0, tagsVal.length - 1);
        }
        let dataVal =
          formData.influxMeasurement.value +
          tagsVal +
          " " +
          fieldsVal.slice(0, fieldsVal.length - 1) +
          " " +
          formData.influxTimestamp.value;
        let config_data = {
          database: formData.influxDatabase.value,
          name: formData.influxName.value,
          server: formData.influxServerHost.value,
          username: formData.influxUsername.value,
          password: formData.influxPassword.value,
          data: dataVal,
        };
        finalData = {
          config_type: "influxdb",
          configuration: JSON.stringify(config_data),
        };
        createUpdateActionApi(finalData, actionData);
      } else {
        return;
      }
    }
  };

  const createUpdateActionApi = (finalData: any, actionData: any) => {
    if (isNewAction) {
      createActionApi(finalData).then((data) => {
        let configJson = JSON.parse(data.data.configuration);
        if (data.data.config_type == "influxdb") {
          actionData.push("influxdb_api_v1:" + configJson.name);
        } else {
          actionData.push("kafka_producer:" + configJson.name);
        }
        sendConfigData(actionData);
      });
    } else {
      updateActionApi(finalData).then((data) => {
        let configJson = JSON.parse(data.data.configuration);
        if (data.data.config_type == "influxdb") {
          actionData.push("influxdb_api_v1:" + configJson.name);
        } else {
          actionData.push("kafka_producer:" + configJson.name);
        }
        sendConfigData(actionData);
      });
    }
  };

  const getTargetQos = (qosName, qosValue) => {
    if (qosName != "") {
      let value = targetQosArr.findIndex(
        (x) => x.targetQosName == qosName.value
      );
      setSelectedTargetQos(targetQosArr[value].targetQosId.toString());
    } else {
      setSelectedTargetQos(qosValue);
    }
  };

  useEffect(() => {
    if (!isNewAction) {
      if (actionType.function == "republish") {
        setFormData(formDataInitRepublish);
        setSelectedActionType("1");
        getTargetQos(formDataInitRepublish.targetQos, "");
        setSelectedTargetRetain(formDataInitRepublish.targetRetain.value);
      } else if (actionType.startsWith("influxdb_api_v1")) {
        setFormData(formDataInitInflux);
        setSelectedActionType("3");
      } else if (actionType.startsWith("kafka")) {
        setFormData(formDataInitkafka);
        setSelectedActionType("2");
        let encodeIndex = kafkaHeaderEncodeArr.findIndex(
          (x) =>
            x.kafkaHeaderEncodeValue ==
            formDataInitkafka.kafkaHeaderValueEncode.value
        );
        setSelectedKafkaEncode(
          kafkaHeaderEncodeArr[encodeIndex].kafkaHeaderEncodeId.toString()
        );
        let compressionIndex = compressionArr.findIndex(
          (x) => x.compressionVal == formDataInitkafka.compression.value
        );
        setSelectedCompression(
          compressionArr[compressionIndex].compressionId.toString()
        );
        let partStrategyIndex = partitionStrategyArr.findIndex(
          (x) => x.partStrategyVal == formDataInitkafka.partStrategy.value
        );
        setSelectedPartStrategy(
          partitionStrategyArr[partStrategyIndex].partStrategyId.toString()
        );
      }
    }
  }, []);

  return (
    <>
      <sdds-modal
        id="modal-prog-create-rule"
        size="md"
        class={`sdds-modal-backdrop hydrated`}
        prevent
        ref={(element) =>
          element?.addEventListener("sddsClose", (event) => {
            cancelModal();
          })
        }
      >
        <h5 slot="sdds-modal-headline">
          {isNewAction ? t("create-rule") : t("update-rule")}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt3 txt-vertical-aln-left sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <div className="sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                  <span className="sdds-dropdown-label-outside">
                    {t("action-type")}
                  </span>
                  <select
                    name="actionType"
                    id="actionType"
                    onChange={handleActionTypeChange}
                    value={selectedActionType}
                    disabled={!isNewAction}
                  >
                    <option value="" disabled selected>
                      {t("select-option")}
                    </option>
                    {actionTypeArr.map((value) => (
                      <option
                        key={value.actionTypeId}
                        value={value.actionTypeId}
                      >
                        {value.actionTypeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {selectedActionType == "1" ? (
                <>
                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.targetTopic.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("target-topic")}
                        class="sdds-on-white-bg"
                        name="targetTopic"
                        value={formData.targetTopic.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("target-topic")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.targetTopic.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <div className="sdds-u-textalign-start sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                        <span className="sdds-dropdown-label-outside">
                          {t("target-Qos")}
                        </span>
                        <select
                          name="targetQos"
                          id="targetQos"
                          onChange={handleInputChange}
                          value={selectedTargetQos}
                        >
                          <option value="" disabled selected>
                            {t("select-option")}
                          </option>
                          {targetQosArr.map((value) => (
                            <option
                              key={value.targetQosId}
                              value={value.targetQosId}
                            >
                              {value.targetQosName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-p0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <div className="sdds-u-textalign-start sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                        <span className="sdds-dropdown-label-outside">
                          {t("target-retain")}
                        </span>
                        <select
                          name="targetRetain"
                          id="targetRetain"
                          onChange={handleInputChange}
                          value={selectedTargetRetain}
                        >
                          <option value="" disabled selected>
                            {t("select-option")}
                          </option>
                          {targetRetainArr.map((value) => (
                            <option
                              key={value.targetRetainValue}
                              value={value.targetRetainValue}
                            >
                              {value.targetRetainName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <sdds-textarea
                      label={t("payload-template")}
                      label-position="outside"
                      placeholder={t("payload-template")}
                      name="payloadTemplate"
                      value={formData.payloadTemplate.value}
                      helper={formData.payloadTemplate.errorMessage}
                      state={
                        formData.payloadTemplate.isValid == true
                          ? "success"
                          : "error"
                      }
                      onInput={handleInputChange}
                    >
                      {" "}
                    </sdds-textarea>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* Kafka  */}
              {selectedActionType == "2" ? (
                <>
                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-textalign-start sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={formData.name.isValid ? "success" : "error"}
                        size="md"
                        placeholder={t("rule-influx-name")}
                        class="sdds-on-white-bg"
                        name="name"
                        value={formData.name.value}
                        onInput={handleInputChange}
                        disabled={!isNewAction}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-name")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.name.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.description.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("create-rule-description")}
                        class="sdds-on-white-bg"
                        name="description"
                        value={formData.description.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("create-rule-description")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.description.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-textalign-start sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.kafkaTopic.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("kafka-topic")}
                        class="sdds-on-white-bg"
                        name="kafkaTopic"
                        value={formData.kafkaTopic.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("kafka-topic")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.kafkaTopic.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={formData.timestamp.isValid ? "success" : "error"}
                        size="md"
                        placeholder={t("timestamp")}
                        class="sdds-on-white-bg"
                        name="timestamp"
                        value={formData.timestamp.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("timestamp")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.timestamp.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.kafkaHeaders.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("kafka-headers")}
                        class="sdds-on-white-bg"
                        name="kafkaHeaders"
                        value={formData.kafkaHeaders.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("kafka-headers")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.kafkaHeaders.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <div className="sdds-u-textalign-start sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                        <span className="sdds-dropdown-label-outside">
                          {t("kafka-header-value-encode")}
                        </span>
                        <select
                          name="kafkaHeaderValueEncode"
                          id="kafkaHeaderValueEncode"
                          onChange={handleInputChange}
                          value={selectedKafkaEncode}
                        >
                          <option value="" disabled selected>
                            {t("select-option")}
                          </option>
                          {kafkaHeaderEncodeArr.map((value) => (
                            <option
                              key={value.kafkaHeaderEncodeId}
                              value={value.kafkaHeaderEncodeId}
                            >
                              {value.kafkaHeaderEncodeName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <span className="sdds-dropdown-label-outside sdds-u-textalign-start">
                      {t("more-kafka-headers")}
                    </span>
                    <div className="overflow-auto">
                      <table className="sdds-table sdds-u-w-100 sdds-table--compact">
                        <thead className="sdds-table__header">
                          <tr className="sdds-table__row">
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("kafka-header-key")}
                            </th>
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("kafka-header-value")}
                            </th>
                            <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                              {formData.headerKeyValList.value.length < 1 ? (
                                <button
                                  id="addNewHeader"
                                  className="sdds-btn sdds-btn-primary sdds-btn-sm  schemaAddBtn"
                                  onClick={(event) => addNewHeader(event)}
                                >
                                  {t("add")}
                                </button>
                              ) : (
                                ""
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="sdds-table__body">
                          {formData.headerKeyValList.value &&
                            formData.headerKeyValList.value.map(
                              (item, index) => (
                                <tr className="sdds-table__row">
                                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                    <input
                                      className={`sdds-on-white-bg txt-bold pad-input-custom  ${
                                        (!item.headerKey &&
                                          formData.headerKeyValList.value[index]
                                            .isChanged) ||
                                        !formData.headerKeyValList.value[index]
                                          .isValid
                                          ? "errorInput"
                                          : ""
                                      }`}
                                      label={t("header-key")}
                                      label-position="outside"
                                      placeholder={t("enter-header-key")}
                                      name="headerKey"
                                      value={item.headerKey}
                                      onInput={(event) =>
                                        handleInputChangeHeaders(event, index)
                                      }
                                    />
                                  </td>
                                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                    <input
                                      className="sdds-on-white-bg txt-bold pad-input-custom sdds-u-w-100"
                                      label={t("header-value")}
                                      label-position="outside"
                                      placeholder={t("enter-header-value")}
                                      name="headerVal"
                                      value={item.headerVal}
                                      onInput={(event) =>
                                        handleInputChangeHeaders(event, index)
                                      }
                                    />
                                  </td>
                                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1 sdds-u-pt0 sdds-u-pb0">
                                    <div className="div-btn-horz">
                                      <button
                                        className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                        onClick={(event) => addNewHeader(event)}
                                      >
                                        <img
                                          className="icon-btn-schema"
                                          src={plusIcon}
                                        />
                                      </button>
                                      {formData.headerKeyValList.value.length >
                                      1 ? (
                                        <button
                                          fill="white"
                                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                          onClick={(event) =>
                                            deleteHeader(event, index)
                                          }
                                        >
                                          <img
                                            fill="white"
                                            className="icon-btn-schema"
                                            src={trashIcon}
                                          />
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-pl0 sdds-u-textalign-start sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textarea
                        label={t("message-key")}
                        label-position="outside"
                        placeholder={t("message-key")}
                        name="messageKey"
                        value={formData.messageKey.value}
                        onInput={handleInputChange}
                        helper={formData.messageKey.errorMessage}
                        state={
                          formData.messageKey.isValid == true
                            ? "success"
                            : "error"
                        }
                      >
                        {" "}
                      </sdds-textarea>
                    </div>

                    <div className="sdds-u-pr0 sdds-u-textalign-start sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textarea
                        label={t("message-value")}
                        label-position="outside"
                        placeholder={t("message-value")}
                        name="messageVal"
                        value={formData.messageVal.value}
                        onInput={handleInputChange}
                        helper={formData.messageVal.errorMessage}
                        state={
                          formData.messageVal.isValid == true
                            ? "success"
                            : "error"
                        }
                      >
                        {" "}
                      </sdds-textarea>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-u-flex sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <div className="sdds-u-textalign-start sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                        <span className="sdds-dropdown-label-outside">
                          {t("partition-strategy")}
                        </span>
                        <select
                          name="partStrategy"
                          id="partStrategy"
                          onChange={handleInputChange}
                          value={selectedPartStrategy}
                        >
                          <option value="" disabled selected>
                            {t("select-option")}
                          </option>
                          {partitionStrategyArr.map((value) => (
                            <option
                              key={value.partStrategyId}
                              value={value.partStrategyId}
                            >
                              {value.partStrategyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="sdds-u-pr0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <div className="sdds-u-textalign-start sdds-dropdown sdds-on-white-bg  sdds-dropdown-medium">
                        <span className="sdds-dropdown-label-outside">
                          {t("compression")}
                        </span>
                        <select
                          name="compression"
                          id="compression"
                          onChange={handleInputChange}
                          value={selectedCompression}
                        >
                          <option value="" disabled selected>
                            {t("select-option")}
                          </option>
                          {compressionArr.map((value) => (
                            <option
                              key={value.compressionId}
                              value={value.compressionId}
                            >
                              {value.compressionName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* Influxdb */}
              {selectedActionType == "3" ? (
                <>
                  <div className="sdds-u-mt3 sdds-u-flex sdds-flex-wrap sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <div className="sdds-u-textalign-start sdds-u-pl0 sdds-u-mb1 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        type="text"
                        state={
                          formData.influxName.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-name")}
                        class="sdds-on-white-bg"
                        name="influxName"
                        value={formData.influxName.value}
                        onInput={handleInputChange}
                        disabled={!isNewAction}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-name")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxName.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>

                    <div className="sdds-u-pr0 sdds-u-mb1 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxServerHost.isValid
                            ? "success"
                            : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-server-host")}
                        class="sdds-on-white-bg"
                        name="influxServerHost"
                        value={formData.influxServerHost.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-server-host")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxServerHost.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>

                    <div className="sdds-u-textalign-start sdds-u-pl0  sdds-u-mb1 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxUsername.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-username")}
                        class="sdds-on-white-bg"
                        name="influxUsername"
                        value={formData.influxUsername.value}
                        onInput={handleInputChange}
                        disabled="true"
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-username")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxUsername.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className=" sdds-u-pr0 sdds-u-mb1  sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxPassword.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-password")}
                        class="sdds-on-white-bg"
                        name="influxPassword"
                        value={formData.influxPassword.value}
                        onInput={handleInputChange}
                        disabled="true"
                        type="password"
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-password")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxPassword.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-textalign-start sdds-u-pl0 sdds-u-mb1 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxDatabase.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-database")}
                        class="sdds-on-white-bg"
                        name="influxDatabase"
                        value={formData.influxDatabase.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-database")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxDatabase.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                    <div className="sdds-u-pr0 sdds-u-mb1 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxMeasurement.isValid
                            ? "success"
                            : "error"
                        }
                        size="md"
                        placeholder={t("rule-influx-measurement")}
                        class="sdds-on-white-bg"
                        name="influxMeasurement"
                        value={formData.influxMeasurement.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("rule-influx-measurement")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxMeasurement.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>

                    <div className="sdds-u-textalign-start sdds-u-mb1 sdds-u-pl0 sdds-col-max-6  sdds-col-xxlg-6  sdds-col-xlg-6  sdds-col-lg-6 sdds-col-md-6 sdds-col-sm-12 sdds-col-xs-12">
                      <sdds-textfield
                        state={
                          formData.influxTimestamp.isValid ? "success" : "error"
                        }
                        size="md"
                        placeholder={t("timestamp")}
                        class="sdds-on-white-bg"
                        name="influxTimestamp"
                        value={formData.influxTimestamp.value}
                        onInput={handleInputChange}
                      >
                        <span
                          slot="sdds-label"
                          className="txt-vertical-aln-left"
                        >
                          {t("timestamp")}
                        </span>
                        <span slot="sdds-helper">
                          {formData.influxTimestamp.errorMessage}
                        </span>
                      </sdds-textfield>
                    </div>
                  </div>

                  <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <span className="sdds-dropdown-label-outside sdds-u-textalign-start">
                      {t("fields")}
                    </span>
                    <div className="overflow-auto">
                      <table className="sdds-table sdds-u-w-100 sdds-table--compact">
                        <thead className="sdds-table__header">
                          <tr className="sdds-table__row">
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("key")}
                            </th>
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("value")}
                            </th>
                            <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                              {formData.fieldKeyValList.value.length < 1 ? (
                                <button
                                  id="addNewField"
                                  className="sdds-btn sdds-btn-primary sdds-btn-sm  schemaAddBtn"
                                  onClick={(event) => addNewField(event)}
                                >
                                  {t("add")}
                                </button>
                              ) : (
                                ""
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="sdds-table__body">
                          {formData.fieldKeyValList.value &&
                            formData.fieldKeyValList.value.map(
                              (item, index) => (
                                <>
                                  <tr className="sdds-table__row no-border">
                                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                      <input
                                        className={`sdds-on-white-bg txt-bold pad-input-custom`}
                                        label={t("field-key")}
                                        label-position="outside"
                                        placeholder={t("enter-field-key")}
                                        name="fieldKey"
                                        value={item.fieldKey}
                                        onInput={(event) =>
                                          handleInputChangeField(event, index)
                                        }
                                      />
                                    </td>
                                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                      <input
                                        className="sdds-on-white-bg txt-bold pad-input-custom sdds-u-w-100"
                                        label={t("field-value")}
                                        label-position="outside"
                                        placeholder={t("enter-field-value")}
                                        name="fieldVal"
                                        value={item.fieldVal}
                                        onInput={(event) =>
                                          handleInputChangeField(event, index)
                                        }
                                      />
                                    </td>
                                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1 sdds-u-pt0 sdds-u-pb0">
                                      <div className="div-btn-horz">
                                        <button
                                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                          onClick={(event) =>
                                            addNewField(event)
                                          }
                                        >
                                          <img
                                            className="icon-btn-schema"
                                            src={plusIcon}
                                          />
                                        </button>
                                        {formData.fieldKeyValList.value.length >
                                        1 ? (
                                          <button
                                            fill="white"
                                            className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                            onClick={(event) =>
                                              deleteField(event, index)
                                            }
                                          >
                                            <img
                                              fill="white"
                                              className="icon-btn-schema"
                                              src={trashIcon}
                                            />
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                  {formData.fieldKeyValList.value[index]
                                    .errorMessage ? (
                                    <div class="sdds-textfield-helper error-message-padding-fields">
                                      <div class="sdds-textfield-helper-wrapper error">
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M8 2.00015C4.6853 2.00015 1.9982 4.68725 1.9982 8.00195C1.9982 11.3167 4.6853 14.0038 8 14.0038C11.3147 14.0038 14.0018 11.3167 14.0018 8.00195C14.0018 4.68725 11.3147 2.00015 8 2.00015ZM1 8.00195C1 4.13596 4.13401 1.00195 8 1.00195C11.866 1.00195 15 4.13596 15 8.00195C15 11.8679 11.866 15.002 8 15.002C4.13401 15.002 1 11.8679 1 8.00195Z"
                                            fill="#FF2340"
                                          ></path>
                                          <path
                                            d="M7.4014 7.2352V5H8.5894V7.2352L8.4134 9.3824H7.5774L7.4014 7.2352ZM7.375 10.0512H8.6246V11.248H7.375V10.0512Z"
                                            fill="#FF2340"
                                          ></path>
                                        </svg>
                                        <span
                                          slot="sdds-helper "
                                          className="fieldTableError"
                                        >
                                          {
                                            formData.fieldKeyValList.value[
                                              index
                                            ].errorMessage
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    {fieldKeyValidationError ? (
                      <p className="fieldTableError">
                        {fieldKeyValidationError}
                      </p>
                    ) : (
                      ""
                    )}
                    {!fieldKeyValidationError &&
                    duplicateFieldKeys.length > 0 ? (
                      <p className="fieldTableError">{t("field-key-error")}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="sdds-u-mt3 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                    <span className="sdds-dropdown-label-outside sdds-u-textalign-start">
                      {t("tags")}
                    </span>
                    <div className="overflow-auto">
                      <table className="sdds-table sdds-u-w-100 sdds-table--compact">
                        <thead className="sdds-table__header">
                          <tr className="sdds-table__row">
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("key")}
                            </th>
                            <th
                              className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                            >
                              {t("value")}
                            </th>
                            <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                              {formData.tagKeyValList.value.length < 1 ? (
                                <button
                                  id="addNewTag"
                                  className="sdds-btn sdds-btn-primary sdds-btn-sm schemaAddBtn "
                                  onClick={(event) => addNewTag(event)}
                                >
                                  {t("add")}
                                </button>
                              ) : (
                                ""
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="sdds-table__body">
                          {formData.tagKeyValList.value &&
                            formData.tagKeyValList.value.map((item, index) => (
                              <tr className="sdds-table__row">
                                <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                  <input
                                    className={`sdds-on-white-bg txt-bold pad-input-custom  ${
                                      (!item.tagKey &&
                                        formData.tagKeyValList.value[index]
                                          .isChanged) ||
                                      duplicateTagKeys.includes(index) ||
                                      !formData.tagKeyValList.value[index]
                                        .isValid
                                        ? "errorInput"
                                        : ""
                                    }`}
                                    label={t("tag-key")}
                                    label-position="outside"
                                    placeholder={t("enter-tag-key")}
                                    name="tagKey"
                                    value={item.tagKey}
                                    onInput={(event) =>
                                      handleInputChangeTag(event, index)
                                    }
                                  />
                                </td>
                                <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left sdds-u-pt0 sdds-u-pb0">
                                  <input
                                    className="sdds-on-white-bg txt-bold pad-input-custom sdds-u-w-100"
                                    label={t("tag-value")}
                                    label-position="outside"
                                    placeholder={t("enter-tag-value")}
                                    name="tagVal"
                                    value={item.tagVal}
                                    onInput={(event) =>
                                      handleInputChangeTag(event, index)
                                    }
                                  />
                                </td>
                                <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1 sdds-u-pt0 sdds-u-pb0">
                                  <div className="div-btn-horz">
                                    <button
                                      className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                      onClick={(event) => addNewTag(event)}
                                    >
                                      <img
                                        className="icon-btn-schema"
                                        src={plusIcon}
                                      />
                                    </button>
                                    {formData.tagKeyValList.value.length > 1 ? (
                                      <button
                                        fill="white"
                                        className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-6 sdds-u-p1"
                                        onClick={(event) =>
                                          deleteTag(event, index)
                                        }
                                      >
                                        <img
                                          fill="white"
                                          className="icon-btn-schema"
                                          src={trashIcon}
                                        />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {tagKeyValidationError ? (
                      <p className="fieldTableError">{tagKeyValidationError}</p>
                    ) : (
                      ""
                    )}
                    {!tagKeyValidationError && duplicateTagKeys.length > 0 ? (
                      <p className="fieldTableError">{t("tag-key-error")}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={cancelModal}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t("cancel")}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t("submit")}
        </button>
      </sdds-modal>
    </>
  );
};

export default CreateRuleModal;
