import React, { useState, useEffect, useContext } from "react";
import {
  getAllRoles,
  updateRole,
  createRole,
  deleteRole,
} from "../../Services/Service.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const RoleModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllRolesFunc,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);
  const closeModalFunc = () => {
    closeModal();
  };
  useEffect(() => {
    setFormData(formDataObj);
  }, [showModal]);
  const [errors, setErrors] = useState({});

  // function to handle input type value changes and store it
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "roleName" || name == "roleCode") {
      if (specialCharsRegex.test(value)) {
        setFormData((prevInputs) => ({
          ...prevInputs,
          [name]: {
            value: value,
            isChanged: true,
            errorMsg: t('special-char-error'),
          },
        }));
      } else {
        setFormData((prevInputs) => ({
          ...prevInputs,
          [name]: { value: value, isChanged: true, errorMsg: "" },
        }));
      }
    }
  };

  // function to submit data either for create new role or edit existing role
  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    if (formData.roleName.value && formData.roleCode.value) {
      const roleData = {
        roleCode: formData.roleCode.value,
        roleDesc: formData.roleDesc.value,
        roleName: formData.roleName.value,
      };
      if (formData.newOrEdit?.value == "edit") {
        const existingRoleData = {
          ...roleData,
          updatedBy: context.session.username,
          roleId: formData.roleId.value,
        };
        //API call for update an existing role
        updateRole(existingRoleData)
          .then((data: any) => {
            closeModal();
            getAllRolesFunc();
          })
          .catch((err) => {
            if (err?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        const newRoleData = {
          ...roleData,
          createdBy: context.session.username,
          status: true,
        };
        // API call for create new role
        createRole(newRoleData)
          .then((data: any) => {
            closeModal();
            getAllRolesFunc();
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
            if (error?.response?.data?.status == 500) {
              showErrorModal(`${t('role')} ${newRoleData.roleName} ${t('exists')}`);
            }
          });
      }
    } else {
      console.log("error");
    }
  };
  return (
    <>
      <sdds-modal
        id="modal-prog-role"
        size="xs"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline">
          {formData.newOrEdit?.value == "edit" ? t('edit-role') : t('create-role')}
        </h5>
        <p slot="sdds-modal-body" style={{ paddingRight: 0 }}>
          <form>
            <div className="sdds-row">
              <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    formData.roleName.errorMsg ||
                    (formData.roleName.isChanged && !formData.roleName.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  placeholder={t('enter-role-name')}
                  className="sdds-on-white-bg txt-bold"
                  name="roleName"
                  value={formData.roleName.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('role-name')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.roleName.isChanged && !formData.roleName.value
                      ? t('role-name-error')
                      : ""}
                    {formData.roleName.errorMsg
                      ? formData.roleName.errorMsg
                      : ""}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textfield
                  state={
                    formData.roleCode.errorMsg ||
                    (formData.roleCode.isChanged && !formData.roleCode.value)
                      ? "error"
                      : "success"
                  }
                  size="md"
                  disabled={formData.newOrEdit?.value == "edit"}
                  placeholder={t('enter-role-code')}
                  className="sdds-on-white-bg txt-bold"
                  name="roleCode"
                  value={formData.roleCode.value}
                  onInput={handleInputChange}
                >
                  <span slot="sdds-label" className="txt-vertical-aln-left">
                    {t('role-code')}
                  </span>
                  <span slot="sdds-helper" className="font-weight-none ">
                    {formData.roleCode.isChanged && !formData.roleCode.value
                      ? t('role-code-error')
                      : ""}
                    {formData.roleCode.errorMsg
                      ? formData.roleCode.errorMsg
                      : ""}
                  </span>
                </sdds-textfield>
              </div>
              <div className="sdds-u-mt2 sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
                <sdds-textarea
                  className="sdds-on-white-bg txt-bold"
                  label={t('role-desc')}
                  label-position="outside"
                  placeholder={t('enter-role-desc')}
                  name="roleDesc"
                  value={formData.roleDesc.value}
                  onInput={handleInputChange}
                ></sdds-textarea>
              </div>
            </div>
          </form>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('cancel')}
        </button>
        <button
          type="submit"
          slot="sdds-modal-actions"
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
          onClick={handleSubmit}
        >
          {t('submit')}
        </button>
      </sdds-modal>
    </>
  );
};

export default RoleModal;
