import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import { PageSize } from "../Constants.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";
import Pagination from "../Components/Pagination.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import {
  deleteRuleById,
  getAllRules,
  getAllProjects,
  getProjectsByprojectCode,
  getRulesByProjectCode,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";

const Rule = ({ openCreateRule }) => {
  const { t } = useTranslation();
  const context = useContext(LoggedInContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [ruleArr, setRuleArr] = useState([]);
  const [projectCode, setProjectCode] = useState(null);
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const { items, requestSort, sortConfig } = useSortableData(ruleArr);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  const getAllRulesFunc = () => {
    if (context.session.projectCode == "All") {
      getAllRules()
        .then((data) => {
          setRuleArr(data.data);
          setCurrentPage(1);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getRulesByProjectCode(context.session.projectCode)
        .then((data) => {
          setRuleArr(data.data);
          setCurrentPage(1);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  useEffect(() => {
    getAllRulesFunc();
    getAllProjectsFunc();
  }, [ruleArr.length, context.session.projectCode]);

  const addNew = () => {
    openCreateRule("createRule", "");
  };

  const editRule = (data) => {
    openCreateRule("createRule", data);
  };

  const deleteRuleFunc = (ruleId: any) => {
    setSelectedRuleId(ruleId);
    setWarningMessage(
      `${t("delete-rule")} "${
        ruleArr[ruleArr.findIndex((x) => x.id == ruleId)].id
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  const confirmedDeleteFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    setModeforResponseMessageModal("");
    deleteRuleById(selectedRuleId)
      .then((data: any) => {
        document.getElementById("confirmationMessageModal").closeModal();
        if (data?.data?.http_status == 409) {
          setResponseMessage(
            `${t("rule-delete-msg")} ${selectedRuleId} ${t(
              "rule-delete-error"
            )}`
          );
        } else {
          setResponseMessage(
            `${t("rule-delete-msg")} ${selectedRuleId} ${t("delete-success")}`
          );
        }
        document.getElementById("responseMessageModal").openModal();
        getAllRulesFunc();
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  //API call to get all the projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects().then((data: any) => {
        setProjectCode(data.data);
      });
    } else {
      getProjectsByprojectCode(context.session.projectCode).then((data) => {
        setProjectCode(data.data);
      });
    }
  };

  return (
    <>
      <div
        className={
          context.session.userRole != "mqtt_mgmt_projectuser"
            ? "search-align sdds-u-mb1"
            : "sdds-u-mb1"
        }
      >
        {context.session.userRole != "mqtt_mgmt_projectuser" ? (
          <button
            className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-mb1 sdds-u-block "
            onClick={addNew}
            disabled={projectCode == 0}
          >
            {t("create-rule")}
          </button>
        ) : (
          ""
        )}
        {projectCode == 0 ? (
          <div class="sdds-message sdds-message__type-warning sdds-message__icon-active sdds-message__extended-active sdds-message-ongrey">
            <svg
              class="sdds-message-icon sdds-message-icon--warning"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25175 9.919V7.75H10.7367V9.919L10.5167 12.603H9.47175L9.25175 9.919ZM9.21875 13.439H10.7807V14.935H9.21875V13.439Z"
                fill="#F1C21B"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 0.875C10.2332 0.875 10.4469 1.00479 10.5545 1.21165L18.6795 16.8367C18.7803 17.0304 18.7726 17.2627 18.6592 17.4494C18.5459 17.636 18.3434 17.75 18.125 17.75H1.875C1.65662 17.75 1.45408 17.636 1.34075 17.4494C1.22742 17.2627 1.21974 17.0304 1.32049 16.8367L9.44549 1.21165C9.55306 1.00479 9.76684 0.875 10 0.875ZM2.90445 16.5H17.0956L10 2.85471L2.90445 16.5Z"
                fill="#F1C21B"
              ></path>
            </svg>
            <h4 class="sdds-message-single">{t("no-project-assigned-info")}</h4>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100 overflow-auto">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 `}>
                {t("rule-id")}
              </th>
              <th className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 `}>
                {t("rule-source")}
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("rule-enable")}
              </th>
              <th className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}>
                {t("rule-note")}
              </th>
              <th className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}>
                {t("rule-action")}
              </th>

              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("rule-actions")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.id}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.id}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left">
                    {value.from[0]}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left">
                    {JSON.stringify(value.enable)}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.description}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.actions.length}
                  </td>
                  {context.session.userRole != "mqtt_mgmt_projectUser" ? (
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                      <div className="div-btn-horz">
                        <button
                          disabled={value.status == "deactive"}
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editRule(value)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() => deleteRuleFunc(value.id)}
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={ruleArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedDeleteFunc}
      />
    </>
  );
};

export default Rule;
