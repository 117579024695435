import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import { PageSize } from "../Constants.tsx";
import CreateSchemaModal from "./Modals/CreateSchemaModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import {
  getAllSchemas,
  deleteSchema,
  getAllProjects,
} from "../Services/Service.tsx";
import { useTranslation } from "react-i18next";

const CreateSchema = () => {
  const { t } = useTranslation();
  const context = useContext(LoggedInContext);
  const [schemaArr, setSchemaArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(schemaArr);
  const [projectArr, setProjectArr] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedSchemaId, setSelectedSchemaId] = useState("");
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [bucketNameVal, setBucketNameVal] = useState("");

  let formDataInit = {
    bucketName: { value: "", isChanged: false },
    measurementName: { value: "", isChanged: false },
    schemaId: { value: "", isChanged: false },
    fieldKeyValList: {
      value: [
        {
          fieldKey: "",
          fieldVal: 0,
          isChanged: false,
          isValid: true,
        },
      ],
    },
    tagKeyValList: {
      value: [
        {
          tagKey: "",
          tagVal: "",
          isChanged: false,
          isValid: true,
        },
      ],
    },
  };

  const [formDataObj, setFormDataObj] = useState(formDataInit);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  const closeModal = () => {
    document.getElementById("modal-prog-schema").closeModal();
  };

  // API call to get all projects
  const getAllProjectsFunc = () => {
    getAllProjects()
      .then((data: any) => {
        if (
          context.session.projectCode != "" &&
          context.session.projectCode != null &&
          context.session.projectCode != undefined
        ) {
          let selectedProjArr = [];
          for (let projCode of context.session.projectCode) {
            for (let selectedProjObj of data.data) {
              if (selectedProjObj.projectCode == projCode) {
                selectedProjArr.push(selectedProjObj);
              }
            }
          }
          setProjectArr(selectedProjArr);
        } else {
          setProjectArr(data.data);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  // API call to get all schemas
  const getAllSchemasFunc = () => {
    getAllSchemas()
      .then((data: any) => {
        if (
          context.session.projectCode != "" &&
          context.session.projectCode != null
        ) {
          let finalSchemaArr = [];
          for (let projSelected of projectArr) {
            let filteredArray = data.data.filter(
              (x) => x.bucketName == projSelected.projectCode
            );
            for (let filteredObj of filteredArray) {
              if (filteredObj != undefined) {
                finalSchemaArr.push(filteredObj);
              }
            }
          }
          setSchemaArr(finalSchemaArr);
        } else {
          setSchemaArr(data.data);
        }
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  const fieldTagValConvert = (arr) => {
    let newArr = [];
    newArr = arr.map((item) => ({
      ...item,
      isChanged: true,
      isValid: true,
    }));
    return newArr;
  };

  const editSchema = (schemaId) => {
    let selectedSchema =
      schemaArr[schemaArr.findIndex((x) => x.schemaId == schemaId)];
    let selectedSchemaDetails = {};
    for (let key in formDataInit) {
      selectedSchemaDetails[key] = {
        value: Array.isArray(selectedSchema[key])
          ? fieldTagValConvert(selectedSchema[key])
          : selectedSchema[key],
        isChanged: false,
      };
    }
    setFormDataObj((prevData) => ({
      ...selectedSchemaDetails,
      ["newOrEdit"]: { value: "edit" },
    }));
    document.getElementById("modal-prog-schema").openModal();
  };

  // Function to open modal for new schema
  const addNew = () => {
    setFormDataObj(formDataInit);
    document.getElementById("modal-prog-schema").openModal();
  };

  // Function to open modal for delete confirmation
  const deleteSchemaFunc = (schemaId, schemaName) => {
    setActDeactOrDelete("delete");
    setSelectedSchemaId(schemaId);
    setBucketNameVal(schemaName);
    setWarningMessage(
      `${t("delete-schema")} "${
        schemaArr[schemaArr.findIndex((x) => x.schemaId == schemaId)].bucketName
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  // Function to call delete API on confirmation
  const confirmedFunc = () => {
    deleteSchema(selectedSchemaId)
      .then((data: any) => {
        setModeforResponseMessageModal("");
        document.getElementById("confirmationMessageModal").closeModal();
        setResponseMessage(
          `${t("schema-bucket-name")} ${bucketNameVal} ${t("delete-success")}`
        );
        document.getElementById("responseMessageModal").openModal();
        getAllSchemasFunc();
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  useEffect(() => {
    getAllProjectsFunc();
    getAllSchemasFunc();
  }, [projectArr.length]);

  return (
    <>
      {context.session.userRole != "mqtt_mgmt_projectuser" ? (
        <button
          className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-mb1 sdds-u-block "
          onClick={addNew}
        >
          {t("create-schema")}
        </button>
      ) : (
        ""
      )}
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("bucketName")}
              >
                {t("bucket-name")}
                <img
                  src={
                    sortConfig?.key == "bucketName" &&
                    getClassName("bucketName") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "bucketName" &&
                    getClassName("bucketName") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("measurementName")}
              >
                {t("measurement-name")}
                <img
                  src={
                    sortConfig?.key == "measurementName" &&
                    getClassName("measurementName") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "measurementName" &&
                    getClassName("measurementName") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.schemaId}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.bucketName}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.measurementName}
                  </td>
                  {context.session.userRole != "mqtt_mgmt_projectuser" ? (
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                      <div className="div-btn-horz">
                        <button
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editSchema(value.schemaId)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            deleteSchemaFunc(value.schemaId, value.bucketName)
                          }
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={schemaArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <CreateSchemaModal
        closeModal={closeModal}
        projectArr={projectArr}
        formDataObj={formDataObj}
        getAllSchemasFunc={getAllSchemasFunc}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
    </>
  );
};

export default CreateSchema;
