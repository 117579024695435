import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import ProjectModal from "./Modals/ProjectModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import ascUpBlack from "../Assets/upArrowBlack.png";
import ascUpBlue from "../Assets/upArrowBlue.png";
import descDownBlack from "../Assets/downArrowBlack.png";
import descDownBlue from "../Assets/downArrowBlue.png";

import {
  getAllProjects,
  updateProject,
  createProject,
  deleteProject,
  actDeactProject,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { PageSize } from "../Constants.tsx";
import useSortableData from "../Hooks/useSorting.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const ProjectPage = () => {
  const { t } = useTranslation();

  let formDataInit = {
    projectName: { value: "", isChanged: false },
    projectDesc: { value: "", isChanged: false },
    projectCode: { value: "", isChanged: false },
    startDate: { value: "", isChanged: false },
    endDate: { value: "", isChanged: false },
    projectId: { value: "", isChanged: false },
  };
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [projectArr, setProjectArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { items, requestSort, sortConfig } = useSortableData(projectArr);
  const [warningMessage, setWarningMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const context = useContext(LoggedInContext);
  const [projectIdBasedOnRole, setProjectIdBasedOnRole] = useState("");
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [projectNameVal, setProjectNameVal] = useState("");
  const [actDeactStatus, setActDeactStatus] = useState("");

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);

  // function to activate and deactivate project
  const handleCheckboxChange = (
    status,
    projectId: Number,
    projectName: string
  ) => {
    setActDeactOrDelete("actDeact");
    setSelectedProjectId(projectId);
    setProjectNameVal(projectName);
    setActDeactStatus(status);
    if (status) {
      setWarningMessage(
        `${t("deactive-project")} "${
          projectArr[projectArr.findIndex((x) => x.projectId == projectId)]
            .projectName
        }" ${t("associated-deactive")}`
      );
    } else {
      setWarningMessage(
        `${t("active-project")} "${
          projectArr[projectArr.findIndex((x) => x.projectId == projectId)]
            .projectName
        }" ${t("associated-active")}`
      );
    }
    document.getElementById("confirmationMessageModal").openModal();
  };

  // Function to change arrow down and up for sorting
  const getClassName = (name) => {
    if (sortConfig && sortConfig.key && sortConfig.direction) {
      if (sortConfig.key === name) {
        return sortConfig.direction;
      }
      return undefined;
    }
  };

  //Function to create new Project
  const addNew = () => {
    setFormDataObj(formDataInit);
    document.getElementById("modal-prog-proj").openModal();
  };

  //Functoin to close Create or Edit modal
  const closeModal = () => {
    document.getElementById("modal-prog-proj").closeModal();
  };

  const confirmedFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    setModeforResponseMessageModal("");
    if (actDeactOrDelete == "actDeact") {
      actDeactProject(selectedProjectId)
        .then((data: any) => {
          document.getElementById("confirmationMessageModal").closeModal();
          if (actDeactStatus) {
            setResponseMessage(
              `${t("project")} ${projectNameVal} ${t("deactive-success")}`
            );
          } else {
            setResponseMessage(
              `${t("project")} ${projectNameVal} ${t("active-success")}`
            );
          }
          document.getElementById("responseMessageModal").openModal();
          getAllProjectsFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteProject(selectedProjectId)
        .then((data: any) => {
          document.getElementById("confirmationMessageModal").closeModal();

          if (data?.data?.http_status == 409) {
            setResponseMessage(
              `${t("project")} ${projectNameVal} ${t("project-delete-error")}`
            );
          } else {
            setResponseMessage(
              `${t("project")} ${projectNameVal} ${t("delete-success")}`
            );
          }
          document.getElementById("responseMessageModal").openModal();
          getAllProjectsFunc();
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on status 500
  const showErrorModal = (message) => {
    document.getElementById("modal-prog-proj").closeModal();
    setResponseMessage(message);
    document.getElementById("responseMessageModal").openModal();
    setModeforResponseMessageModal("error");
  };

  //Function to send selected role details for delete to Confirmation modal
  const deleteProjFunc = (projectId: any, projectName: string) => {
    setSelectedProject(
      projectArr[projectArr.findIndex((x) => x.projectId == projectId)]
        .projectName
    );
    setActDeactOrDelete("delete");
    setSelectedProjectId(projectId);
    setProjectNameVal(projectName);
    setWarningMessage(
      `${t("delete-project")} "${
        projectArr[projectArr.findIndex((x) => x.projectId == projectId)]
          .projectName
      }" ?`
    );
    document.getElementById("confirmationMessageModal").openModal();
  };

  //Function to edit the selected Projet
  const editProj = (projectId: any) => {
    let selectedProject =
      projectArr[projectArr.findIndex((x) => x.projectId == projectId)];
    let selectedProjectDetails = {};
    for (let key in formDataInit) {
      if (key == "startDate" || key == "endDate") {
        selectedProjectDetails[key] = {
          value:
            selectedProject[key] != (null && "")
              ? convertEpocToDate(selectedProject[key])
              : "",
          isChanged: false,
        };
      } else {
        selectedProjectDetails[key] = {
          value: selectedProject[key],
          isChanged: false,
        };
      }
    }

    setFormDataObj((prevData) => ({
      ...selectedProjectDetails,
      ["newOrEdit"]: { value: "edit" },
    }));
    document.getElementById("modal-prog-proj").openModal();
  };

  // API call to get all roles
  const getAllProjectsFunc = () => {
    getAllProjects()
      .then((data: any) => {
        if (
          context.session.projectCode != "" &&
          context.session.projectCode != null &&
          context.session.projectCode != undefined
        ) {
          let finalProjArr = [];
          for (let projCode of context.session.projectCode) {
            let projArr = data.data.filter(
              (element) => element.projectCode == projCode
            );
            finalProjArr.push(projArr[0]);
          }
          setProjectArr(finalProjArr);
        } else {
          setProjectArr(data.data);
        }

        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  useEffect(() => {
    getAllProjectsFunc();
  }, []);

  // function to convert EPOC time to date
  const convertEpocToDate = (epocValue: any) => {
    const date = new Date(epocValue);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // get project status
  const getStatus = (status) => {
    return status == "active" ? true : false;
  };

  return (
    <>
      {context.session.userRole == "mqtt_mgmt_admin" ? (
        <button
          className="sdds-btn sdds-btn-primary sdds-btn-sm align-left sdds-u-mb1 sdds-u-block "
          onClick={addNew}
        >
          {t("create-project")}
        </button>
      ) : (
        ""
      )}
      <div className="overflow-auto">
        <table className="sdds-table sdds-u-w-100 overflow-auto">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 `}
                onClick={() => requestSort("projectCode")}
              >
                {t("project-code")}
                <img
                  src={
                    sortConfig?.key == "projectCode" &&
                    getClassName("projectCode") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "projectCode" &&
                    getClassName("projectCode") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 `}
                onClick={() => requestSort("projectName")}
              >
                {t("project-name")}
                <img
                  src={
                    sortConfig?.key == "projectName" &&
                    getClassName("projectName") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "projectName" &&
                    getClassName("projectName") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {t("project-desc")}
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("startDate")}
              >
                {t("start-date")}
                <img
                  src={
                    sortConfig?.key == "startDate" &&
                    getClassName("startDate") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "startDate" &&
                    getClassName("startDate") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th
                className={`sdds-table__header-cell sdds-u-pl2 sdds-u-pr2`}
                onClick={() => requestSort("endDate")}
              >
                {t("end-date")}
                <img
                  src={
                    sortConfig?.key == "endDate" &&
                    getClassName("endDate") == "ascending"
                      ? ascUpBlue
                      : ascUpBlack
                  }
                  className="arrowCss"
                />
                <img
                  src={
                    sortConfig?.key == "endDate" &&
                    getClassName("endDate") != "ascending"
                      ? descDownBlue
                      : descDownBlack
                  }
                  className="arrowCss"
                />
              </th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {currentTableData &&
              currentTableData.map((value) => (
                <tr className="sdds-table__row" key={value.projectId}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.projectCode}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left">
                    {value.projectName}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.projectDesc}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.startDate != (null && "")
                      ? convertEpocToDate(value.startDate)
                      : ""}
                  </td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 txt-vertical-aln-left ">
                    {value.endDate != (null && "")
                      ? convertEpocToDate(value.endDate)
                      : ""}
                  </td>
                  {context.session.userRole == "mqtt_mgmt_admin" ? (
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 sdds-u-mw-75 sdds-u-p1">
                      <div className="div-btn-horz">
                        <button
                          disabled={value.status == "deactive"}
                          className="sdds-btn sdds-btn-primary sdds-btn-sm alignCenter ht-8"
                          onClick={() => editProj(value.projectId)}
                        >
                          <img className="icon-btn" src={editIcon} />
                        </button>
                        <button
                          fill="white"
                          className="sdds-btn sdds-btn-danger sdds-btn-sm alignCenter ht-8"
                          onClick={() =>
                            deleteProjFunc(value.projectId, value.projectName)
                          }
                        >
                          <img
                            fill="white"
                            className="icon-btn"
                            src={trashIcon}
                          />
                        </button>
                        <div className="sdds-toggle " tabindex="0">
                          <input
                            type="checkbox"
                            className="sdds-toggle-input"
                            checked={getStatus(value.status)}
                            onChange={() =>
                              handleCheckboxChange(
                                getStatus(value.status),
                                value.projectId,
                                value.projectName
                              )
                            }
                          />
                          <span className="sdds-toggle-switch"></span>
                        </div>
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={projectArr.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <ProjectModal
        formDataObj={formDataObj}
        closeModal={closeModal}
        getAllProjectsFunc={getAllProjectsFunc}
        showErrorModal={showErrorModal}
      />
      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};

export default ProjectPage;
