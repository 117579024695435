import React, { useState, useContext, useEffect } from "react";
import ControlCenter from "./ControlCenter.tsx";
import ProjectPage from "./ProjectPage.tsx";
import Topic from "./Topic.tsx";
import Role from "./Role.tsx";
import CreateSchema from "./CreateSchema.tsx";
import "../App.css";
import ClientUser from "./ClientUser.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import Rule from "./Rule.tsx";
import CreateRule from "./CreateRule.tsx";

const Dashboard = ({ selectedMenu, loadPage }) => {
  const context = useContext(LoggedInContext);
  const [ruleData, setRuleData] = useState();

  const openCreateRule = (menu, data) => {
    setRuleData(data);
    loadPage(menu);
  };

  return (
    <div className="sdds-container paddingTop max-wd-100">
      {selectedMenu == "project" ? <ProjectPage /> : null}
      {selectedMenu == "control" ? <ControlCenter /> : null}
      {selectedMenu == "role" ? <Role /> : null}
      {selectedMenu == "topic" ? <Topic /> : null}
      {selectedMenu == "client" ? <ClientUser /> : null}
      {selectedMenu == "schema" ? <CreateSchema /> : null}
      {selectedMenu == "rule" ? <Rule openCreateRule={openCreateRule} /> : null}
      {selectedMenu == "createRule" ? <CreateRule openCreateRule={openCreateRule} ruleData={ruleData}/> : null}
    </div>
  );
};

export default Dashboard;
