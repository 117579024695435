import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ message, confirmedFunc }) => {
  const { t } = useTranslation();

  const borderTopStyle = {
    paddingRight: 0,
    paddingBottom: 0,
  };

  // function to close the modal
  const closeModalFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
  };

  const okFunc = () => {
    confirmedFunc();
  };

  return (
    <>
      <sdds-modal
        size="xs"
        id="confirmationMessageModal"
        class={`sdds-modal-backdrop hydrated`}
        prevent
      >
        <h5 slot="sdds-modal-headline"></h5>
        <p slot="sdds-modal-body" style={borderTopStyle}>
          <div className="sdds-row content-center">
            <p>{message}</p>
          </div>
        </p>
        <button
          slot="sdds-modal-actions"
          onClick={closeModalFunc}
          className="sdds-btn sdds-btn-ghost sdds-btn-sm border-grey"
        >
          {t('no')}
        </button>
        <button
          slot="sdds-modal-actions"
          onClick={okFunc}
          className="sdds-btn sdds-btn-primary sdds-btn-sm"
        >
          {t('yes')}
        </button>
      </sdds-modal>
    </>
  );
};

export default ConfirmationModal;
